import styled from "styled-components";

export const Container = styled.button`
  cursor: pointer;

  svg {
    stroke: ${({ theme, active }) =>
      active ? theme.prevColors.yellow : theme.prevColors.grey};
    fill: ${({ theme, active }) =>
      active ? theme.prevColors.yellow : "transparent"};

    &:hover,
    &:focus {
      stroke: ${({ theme }) => theme.prevColors.yellow};
      fill: ${({ theme }) => theme.prevColors.yellow};
    }
  }
`;
