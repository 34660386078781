import { PhotoCard } from "@wrstudios/components";
import { connect } from "react-redux";

function select(
  { streams, listings, user },
  { streamId, deleteStream, footer }
) {
  const stream = streams.byId[streamId] || {};
  const criteria = stream.criteria || [];

  return {
    url: `/streams/${streamId}`,
    name: stream.name || "",
    photos: getPhotos({ stream, listings }),
    lineOne: stream.locationsFormatted || "No Location",
    lineTwo: criteria.length ? criteria : [stream.propTypeFormatted],
    hasLineTwo: !!criteria.length || !!stream.propTypeFormatted,
    lineTwoFallback: "No Criteria",
    showMenu: user.isAgent,
    isDeleting: stream.isDeleting || false,
    deleteLabel: "Delete Stream",
    handleClickDelete: deleteStream,
    footer
  };
}

function getPhotos({ stream, listings }) {
  const firstThreeListingIds = stream.firstThreeListingIds || [];
  const [firstPhoto, secondPhoto, thirdPhoto] = firstThreeListingIds
    .map((listingId) => listings.byId[listingId])
    .filter(Boolean)
    .map((listing) => listing.firstPhoto);
  // This is to fill the array to always have 3 photos
  // even if we don't have three imaages.
  return [firstPhoto, secondPhoto, thirdPhoto];
}

export default connect(select)(PhotoCard);
