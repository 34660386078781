import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover
} from "@reach/combobox";
import { UserAvatar } from "@wrstudios/components";
import matchSorter from "match-sorter";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import dropdownArrow from "../../../../static/images/icon-dropdown-arrow.svg";
import { useStreamModal } from "../../StreamModal";
import { alertTemplates, emailFrequencies, getFrequencyName } from "../utils";

export default function StreamInviteUser() {
  const { isAgent, primaryAlertType, streamSubscribers, clients, cobuyers } =
    useSelector(
      ({
        user: { isAgent, sharedClients },
        clients,
        stream: { primaryAlertType, currentStreamId },
        streams
      }) => ({
        isAgent,
        primaryAlertType,
        cobuyers: Object.values(sharedClients.clients),
        streamSubscribers:
          (streams.byId[currentStreamId] || {}).subscribers || [],
        clients: Object.values(clients.byId)
      })
    );

  const {
    state: { peopleToInvite, canAddAnotherInvitee },
    dispatch
  } = useStreamModal();

  const inviteeCount = peopleToInvite.length;
  const isAutomaticAlertsType = primaryAlertType === alertTemplates.AUTOMATIC;

  const [selectedInviteeFromDropdown, setSelectedInviteeFromDropdown] =
    useState(false);

  // focus the newly added name input after adding a new invitee fieldset
  useEffect(() => {
    if (inviteeCount > 0) {
      document
        .querySelector(`[aria-labelledby=invitee-${inviteeCount - 1}-name]`)
        .focus();
    }
  }, [inviteeCount]);

  useEffect(() => {
    if (inviteeCount === 0 && addInviteeRef.current) {
      addInviteeRef.current.focus();
    }
  }, [inviteeCount]);

  useEffect(() => {
    if (selectedInviteeFromDropdown) {
      addInviteeRef.current.focus();
      setSelectedInviteeFromDropdown(false);
    }
  }, [selectedInviteeFromDropdown]);

  const nonSelectedClients = useMemo(() => {
    const people = isAgent ? clients : cobuyers;
    const subscribedEmails = streamSubscribers.map((sub) => sub.email);
    const queuedInvites = peopleToInvite.map((client) => client.email);
    return people.filter(
      (person) =>
        ![...subscribedEmails, ...queuedInvites].includes(person.email)
    );
  }, [isAgent, clients, cobuyers, streamSubscribers, peopleToInvite]);

  const automaticAlertFrequencyOptions = useMemo(
    () =>
      Object.values(emailFrequencies).filter(
        (f) => f !== emailFrequencies.RECOMMENDATIONS
      ),
    []
  );

  function filterClients(inputValue) {
    return matchSorter(nonSelectedClients, inputValue, {
      keys: ["name", "email"]
    });
  }

  function handleInputChange({ inviteeIndex, keyToChange, value }) {
    return dispatch({
      type: "INVITEE_INPUT_CHANGE",
      payload: {
        inviteeIndex,
        keyToChange,
        value
      }
    });
  }

  function handleClientSelection({ inviteeIndex, selectedClient }) {
    dispatch({
      type: "SELECT_INVITEE",
      payload: { inviteeIndex, selectedClient }
    });
    setSelectedInviteeFromDropdown(true);
  }

  function handleFrequencySelection({ inviteeIndex, selectedFrequency }) {
    return dispatch({
      type: "SELECT_INVITEE_FREQUENCY",
      payload: {
        inviteeIndex,
        selectedFrequency
      }
    });
  }

  const addInviteeRef = useRef(null);

  return (
    <Container>
      {peopleToInvite.map((invitee, inviteeIndex) => {
        const nameAriaLabel = `invitee-${inviteeIndex}-name`;
        const emailAriaLabel = `invitee-${inviteeIndex}-email`;
        const frequencyLabel = `invitee-${inviteeIndex}-frequency`;
        const isInvalidName = invitee.nameTouched && !invitee.nameValid;
        const isInvalidEmail = invitee.emailTouched && !invitee.emailValid;

        return (
          <InviteeFieldset key={inviteeIndex}>
            <Field isInvalid={isInvalidName}>
              <Combobox
                onSelect={(selectedClient) =>
                  handleClientSelection({ inviteeIndex, selectedClient })
                }>
                <LabelAndValidationLayout>
                  <FieldLabel
                    htmlFor={`listbox:${inviteeIndex * inviteeCount + 1}`}>
                    Name
                  </FieldLabel>
                  {isInvalidName && <Validation>Required</Validation>}
                </LabelAndValidationLayout>
                <ComboboxInput
                  value={invitee.name}
                  onBlur={() =>
                    dispatch({
                      type: "VALIDATE_NAME",
                      payload: { inviteeIndex }
                    })
                  }
                  onChange={(e) =>
                    handleInputChange({
                      inviteeIndex,
                      keyToChange: "name",
                      value: e.target.value
                    })
                  }
                  autocomplete={false}
                  placeholder="Full Name"
                  aria-labelledby={nameAriaLabel}
                />
                <ComboboxPopover portal={false}>
                  <ComboboxList aria-labelledby={nameAriaLabel}>
                    {filterClients(invitee.name).map((client) => (
                      <ComboboxOption value={client} key={client.id}>
                        <UserAvatar
                          initials={client.initials}
                          initialsColor={client.initialsColor}
                          avatarUrl={client.avatarUrl}
                          size="xxs"
                        />
                        <InviteeName>{client.name}</InviteeName>
                      </ComboboxOption>
                    ))}
                  </ComboboxList>
                </ComboboxPopover>
              </Combobox>
            </Field>
            <Field isInvalid={isInvalidEmail}>
              <Combobox
                onSelect={(selectedClient) =>
                  handleClientSelection({ inviteeIndex, selectedClient })
                }>
                <LabelAndValidationLayout>
                  <FieldLabel
                    htmlFor={`listbox:${inviteeIndex * inviteeCount + 2}`}>
                    Email
                  </FieldLabel>
                  {isInvalidEmail && <Validation>Required</Validation>}
                </LabelAndValidationLayout>
                <ComboboxInput
                  value={invitee.email}
                  onBlur={() =>
                    dispatch({
                      type: "VALIDATE_EMAIL",
                      payload: { inviteeIndex }
                    })
                  }
                  onChange={(e) =>
                    handleInputChange({
                      inviteeIndex,
                      keyToChange: "email",
                      value: e.target.value
                    })
                  }
                  autocomplete={false}
                  placeholder="email@domain.com"
                  aria-labelledby={emailAriaLabel}
                />
                <ComboboxPopover portal={false}>
                  <ComboboxList aria-labelledby={emailAriaLabel}>
                    {filterClients(invitee.email).map((client) => (
                      <ComboboxOption value={client} key={client.id}>
                        {client.email}
                      </ComboboxOption>
                    ))}
                  </ComboboxList>
                </ComboboxPopover>
              </Combobox>
            </Field>
            {isAutomaticAlertsType && (
              <Field>
                <EmailFrequencySelect>
                  <FieldLabel htmlFor={frequencyLabel}>Alert</FieldLabel>
                  <Select
                    value={invitee.frequency}
                    onChange={(e) =>
                      handleFrequencySelection({
                        inviteeIndex,
                        selectedFrequency: e.target.value
                      })
                    }
                    id={frequencyLabel}>
                    {automaticAlertFrequencyOptions.map((freq) => (
                      <option value={freq} key={freq}>
                        {getFrequencyName(freq)}
                      </option>
                    ))}
                  </Select>
                </EmailFrequencySelect>
              </Field>
            )}
          </InviteeFieldset>
        );
      })}
      <AddInvitee
        id="add-invitee"
        ref={addInviteeRef}
        disabled={!canAddAnotherInvitee}
        onClick={() =>
          dispatch({
            type: "ADD_INVITEE_FIELDSET",
            payload: primaryAlertType
          })
        }>
        + Add Client
      </AddInvitee>
    </Container>
  );
}

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const InviteeFieldset = styled.div`
  display: flex;
  width: 100%;
  margin: 0 0 1.6rem;

  > * + * {
    margin-left: 1.2rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    flex-direction: column;
    padding-bottom: 1.6rem;
    border-bottom: 0.1rem solid ${({ theme }) => theme.prevColors.greyLighter};

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
    }

    > * + * {
      margin-top: 1rem;
      margin-left: 0;
    }
  }
`;

const Field = styled.div`
  flex: 1 1 auto;

  [data-reach-combobox] {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  [data-reach-combobox-input] {
    ${({ theme }) => theme.inputs.base};
    ${({ theme, isInvalid }) =>
      isInvalid &&
      css`
        border: 0.2rem solid ${theme.prevColors.red};
      `}
  }
  [data-reach-combobox-popover] {
  }
  [data-reach-combobox-list] {
    position: absolute;
    top: 6.2rem;
    width: 100%;
    max-height: 40rem;
    list-style: none;
    overflow: hidden;
    border-radius: 3px;
    box-shadow: ${({ theme }) => theme.shadows.overlay};
    background-color: ${({ theme }) => theme.prevColors.white};
    padding: 0;
    margin: 0;
    z-index: 11;
  }
  [data-reach-combobox-option] {
    display: flex;
    align-items: center;
    height: 4rem;
    font-size: 1.4rem;
    line-height: 2.2rem;
    font-weight: 400;
    padding: 0 1.6rem;
    z-index: 1;
    cursor: pointer;
  }
  [data-reach-combobox-option][data-highlighted] {
    font-weight: 600;
    background-color: ${({ theme }) => theme.prevColors.whiteDark};
  }
  [data-reach-combobox-button] {
  }

  /* the string portions that match what the user has typed */
  [data-user-value] {
  }
  /* the string portions are suggested */
  [data-suggested-value] {
  }
`;

const FieldLabel = styled.label`
  display: block;
  margin-bottom: 0.6rem;
  font-weight: 600;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.prevColors.greyDark};
  line-height: 1.8rem;
`;

const EmailFrequencySelect = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const LabelAndValidationLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Validation = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.8rem;
  margin-bottom: 0.6rem;
  color: ${({ theme }) => theme.prevColors.red};
`;

const InviteeName = styled.span`
  margin-left: 1rem;
`;

const Select = styled.select`
  ${({ theme }) => theme.inputs.base};
  position: relative;
  width: 12rem;
  padding-right: 3rem;
  text-align: left;
  cursor: pointer;
  background-image: url(${dropdownArrow});
  background-position: right 1.2rem top 1.6rem;
  background-repeat: no-repeat;
`;

const AddInvitee = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 4rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 700;
  background-color: ${({ theme }) => theme.prevColors.whiteDark};
  color: ${({ theme }) => theme.prevColors.streamsBlue};
  padding-left: 1.6rem;
  border: 0.2rem solid ${({ theme }) => theme.prevColors.whiteDark};
  border-radius: 0.3rem;
  transition: color 200ms linear;
  cursor: pointer;

  &:focus {
    border: 0.2rem solid ${({ theme }) => theme.prevColors.streamsBlue};
    outline: none;
  }

  &:disabled {
    color: ${({ theme }) => theme.prevColors.streamsBlue};
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
