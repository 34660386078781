import { Portal } from "@wrstudios/components";
import { history } from "@wrstudios/utils";
import FocusTrap from "focus-trap-react";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import IconKeyboardArrowLeft from "../icons/IconKeyboardArrowLeft";
import UserPhoneVerify from "../user/connected/UserPhoneVerify";
import StreamEmailFrequency from "./StreamEmailFrequency";
import StreamSmsFrequency from "./StreamSmsFrequency";
import { emailFrequencies, smsFrequencies } from "./invite/utils";
import {
  Actions,
  AlertPreferences,
  AlertsPanel,
  AlertsPanelHeading,
  Back,
  Button,
  CardHeading,
  CardInstructions,
  Container,
  Flash,
  Input,
  PhoneVerifyLayout,
  SignupCardForm
} from "./styled/stream-signup-modal";

function StreamSignupModal({
  name: nameSetByAgent,
  email: emailSetByAgent,
  phone,
  isAcceptingInvitation,
  acceptInvitationError,
  alertsAreAutomatic,
  setAppScrollable,
  invitation,
  railsApiToken,
  acceptInvitation,
  streamId
}) {
  const [name, setName] = useState(nameSetByAgent);
  const [email, setEmail] = useState(emailSetByAgent);
  const [password, setPassword] = useState("");
  const [emailFrequency, setEmailFrequency] = useState(
    invitation.emailFrequency
  );
  const [smsFrequency, setSmsFrequency] = useState(smsFrequencies.NEVER);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalRect, setModalRect] = useState({});

  useEffect(() => {
    const modalRect = modalRef.current.getBoundingClientRect();
    setAppScrollable(false);
    setModalRect(modalRect);

    return () => {
      if (streamId) {
        history.push({ pathname: `/streams/${streamId}`, search: "" });
      } else {
        history.push({ pathname: "/streams/new", search: "" });
      }

      setAppScrollable(true);
    };
  }, [streamId, setAppScrollable]);

  useEffect(() => {
    if (isSubmitting) {
      acceptInvitation({
        name,
        email,
        password,
        passwordConfirmation: password, // * design does not stipulate a pw confirmation field
        frequency: emailFrequency,
        token: railsApiToken,
        ...invitation
      });
    }
  }, [
    acceptInvitation,
    email,
    emailFrequency,
    invitation,
    isSubmitting,
    name,
    password,
    railsApiToken
  ]);

  const modalRef = useRef();

  return (
    <Portal>
      <FocusTrap>
        <Container>
          <SignupCardForm ref={modalRef}>
            <CardHeading>Sign up to know first</CardHeading>
            <CardInstructions>
              Receiving alerts directly from the MLS has never been faster and
              easier. Signing up is free and secure.
            </CardInstructions>
            {acceptInvitationError && (
              <Flash status="error">{acceptInvitationError}</Flash>
            )}
            {/* TODO: if the email address can be edited w/o breaking backend, we should make this field editable @rob */}
            <Input
              type="email"
              value={email}
              placeholder="you@example.com"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              type="name"
              value={name}
              placeholder="Full Name"
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              type="password"
              value={password}
              placeholder="Create a password"
              onChange={(e) => setPassword(e.target.value)}
            />
            {alertsAreAutomatic && (
              <AlertsPanel>
                <AlertsPanelHeading>My Alerts</AlertsPanelHeading>
                <AlertPreferences>
                  <StreamEmailFrequency
                    initialValue={emailFrequency}
                    onSelect={setEmailFrequency}
                  />
                  <StreamSmsFrequency
                    phone={phone}
                    initialValue={smsFrequency}
                    onSelect={setSmsFrequency}
                    renderVerifyComponent={({
                      phone,
                      onVerifySuccess,
                      onClose
                    }) => (
                      <PhoneVerifyLayout
                        style={{
                          top: `${modalRect.top || 0}px`,
                          left: `${modalRect.left || 0}px`,
                          width: `${modalRect.width || 0}px`,
                          height: `${modalRect.height || 0}px`
                        }}>
                        <UserPhoneVerify
                          phone={phone}
                          onSuccess={onVerifySuccess}
                          onClose={onClose}
                        />
                        <Actions>
                          <Back onClick={onClose}>
                            <IconKeyboardArrowLeft />
                            Back to alert options
                          </Back>
                        </Actions>
                      </PhoneVerifyLayout>
                    )}
                  />
                </AlertPreferences>
              </AlertsPanel>
            )}
            <Button
              className="helix-btn helix-btn--primary helix-btn--large"
              type="submit"
              disabled={isAcceptingInvitation || password.length < 4}
              large={true}
              onClick={() => setIsSubmitting(true)}>
              {isAcceptingInvitation ? "One Moment..." : "Continue"}
            </Button>
          </SignupCardForm>
        </Container>
      </FocusTrap>
    </Portal>
  );
}

StreamSignupModal.propTypes = {
  invitation: PropTypes.shape({
    agentId: PropTypes.string,
    clientId: PropTypes.string,
    invitationGuid: PropTypes.string,
    streamSubscriptionId: PropTypes.string,
    emailFrequency: PropTypes.oneOf(Object.values(emailFrequencies))
  }),
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  railsApiToken: PropTypes.string.isRequired,
  acceptInvitationError: PropTypes.string,
  alertsAreAutomatic: PropTypes.bool.isRequired,
  isAcceptingInvitation: PropTypes.bool.isRequired,
  acceptInvitation: PropTypes.func.isRequired,
  setAppScrollable: PropTypes.func.isRequired
};

export default StreamSignupModal;
