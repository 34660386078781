import styled from "styled-components";

export const Container = styled.form`
  width: 100%;
  height: ${({ isEditingCriteria }) =>
    isEditingCriteria ? "calc(100% - 6rem)" : "100%"};
  padding: 2.4rem;
  background-color: ${({ theme }) => theme.prevColors.white};
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
`;
