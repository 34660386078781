import { UserAvatar as BaseAvatar } from "@wrstudios/components";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem 0 2.4rem;
`;

export const SectionHeading = styled.h4`
  ${({ theme }) => theme.text.h4};
  margin-bottom: 1.6rem;
`;

export const UserLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    flex-direction: column;
    align-items: flex-start;

    & > * + * {
      margin-top: 1.6rem;
    }
  }
`;

export const Avatar = styled(BaseAvatar)`
  margin-right: 1.2rem;
  width: 4rem;
  height: 4rem;
`;

export const CurrentUser = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.prevColors.greyDark};
`;

export const FrequenciesLayout = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 3rem;
  }
`;

export const VerifyStep = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 512px;
  z-index: 11;
  background-color: white;
  margin: 0;
  border-radius: 0.6rem;
  ${({ theme }) => theme.layers.modal}
  border: 0.1rem solid ${({ theme }) => theme.prevColors.greyLighter};
`;

export const Actions = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.4rem 1.6rem;
  text-align: center;
  background-color: ${({ theme }) => theme.prevColors.whiteDark};
  border-radius: 0 0 0.6rem 0.6rem;
`;

export const Back = styled.button`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
  padding: 0.8rem 0;
  color: ${({ theme }) => theme.prevColors.streamsBlue};
  background-color: transparent;
  border: 0;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.prevColors.streamsBlue};
  }
`;
