import { UserAvatar } from "@wrstudios/components";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  margin-left: -0.4rem;
  border-radius: 50%;
  box-shadow: ${({ theme }) => `0 0 0 0.2rem ${theme.prevColors.white}`};
`;

export const Avatar = styled(UserAvatar)`
  font-weight: 600;
  opacity: ${({ isInvitee }) => isInvitee && 0.4};
`;

export const Body = styled.div`
  cursor: ${({ isAgent }) => (isAgent ? "pointer" : "default")};
`;

export const Name = styled.strong`
  display: block;
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
`;

export const Message = styled.i`
  display: block;
  white-space: nowrap;
  text-align: center;
`;
