import { UserAvatar as BaseAvatar } from "@wrstudios/components";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
  text-align: center;
  overflow: hidden;

  svg {
    width: 6rem;
    height: 6rem;
  }
`;

export const Avatar = styled(BaseAvatar)`
  margin: 0 0 2.2rem;
`;

export const Change = styled.button`
  margin-bottom: 1.4rem;
`;

export const Remove = styled.button`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  padding: 0;
  border: 0;
`;

export const Input = styled.input`
  position: absolute;
  left: -9999rem;
  top: 0;
`;

export const Button = styled.button`
  margin-left: 1rem;

  &:first-of-type {
    margin-left: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    width: 100%;
    justify-content: center;
  }
`;
