import styled, { css } from "styled-components";

export const Container = styled.div`
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  text-align: center;
  margin-top: 4rem;
`;

export const PageControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const button = css`
  flex-shrink: 0;
  display: inline-block;
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
  border-radius: 0.4rem;
  border: 0.2rem solid transparent;
  color: ${({ theme }) => theme.prevColors.greyMedium};
  cursor: pointer;

  &[disabled] {
    cursor: default;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    width: 3.6rem;
    height: 3.6rem;
    font-size: 1.4rem;
    margin-right: 0.8rem;
  }
`;

export const PageButton = styled.button`
  ${button};
  border-color: ${({ theme, isSelected }) =>
    isSelected ? theme.prevColors.greyLight : theme.prevColors.greyLighter};
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.prevColors.greyLighter : "transparent"};
`;

export const DirectionButton = styled.button`
  ${button};
  border-color: ${({ theme }) => theme.prevColors.greyLighter};

  svg {
    fill: ${({ theme }) => theme.prevColors.greyMedium};
  }
`;

export const Ellipsis = styled.div`
  ${button};
  width: 2.4rem;
  font-size: 2.4rem;
  border-color: transparent;
  cursor: default;
  transform: translateY(-0.5rem);

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    width: 2.4rem;
    font-size: 2.4rem;
  }
`;

export const ViewingInfo = styled.div`
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.prevColors.greyMidLight};
`;

export const SelectContainer = styled.div`
  width: 11.5rem;
  margin: 0 auto;
  text-align: right;
  color: ${({ theme }) => theme.prevColors.black};
  position: relative;

  svg {
    width: 1.8rem;
    height: 1.8rem;
    fill: ${({ theme }) => theme.prevColors.black};
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    right: 0;
  }
`;

export const Select = styled.select`
  display: inline-block;
  width: auto;
  font-size: 1.4rem;
  line-height: 2rem;
  padding-right: 3rem;
  border: 0;
  background-color: transparent;
  color: ${({ theme }) => theme.prevColors.black};
`;
