import React from "react";

const IllustrationCuratedAlerts = (props) => (
  <svg width={186} height={78} {...props}>
    <defs>
      <path
        d="M10.866 12H9.732a2 2 0 0 1-3.464 0h4.598zM12 11H4v-1c.332-.021.53-.07.594-.149C4.988 9.376 5 8.171 5 7c0-2.182 2-3 3-3s3 .823 3 3c0 1.166.013 2.373.406 2.85.065.08.263.13.594.15v1zM7.5 3h1v2h-1V3z"
        id="CuratedAlertsIllustration_svg__a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(66)">
        <rect
          stroke="#576474"
          x={0.5}
          y={7}
          width={52.69}
          height={58.69}
          rx={2.4}
        />
        <path
          d="M27 21.83L14.39 32.75v12.07h9.87v-6.7a2.61 2.61 0 1 1 5.22 0v6.66h9.82V32.93L27 21.83z"
          fill="#576474"
          fillRule="nonzero"
        />
        <path
          d="M32.39 53.52a.51.51 0 0 0-.41-.34l-3.24-.47-1.45-2.93a.52.52 0 0 0-.89 0L25 52.71l-3.24.47a.5.5 0 0 0-.28.85l2.35 2.29-.55 3.23a.48.48 0 0 0 .19.48.49.49 0 0 0 .53 0l2.9-1.52 2.89 1.52a.53.53 0 0 0 .24.06.46.46 0 0 0 .29-.1.5.5 0 0 0 .2-.48l-.56-3.23 2.3-2.28a.53.53 0 0 0 .13-.48z"
          fill="#F6A807"
          fillRule="nonzero"
        />
        <g transform="translate(19)">
          <circle fill="#236847" cx={8} cy={8} r={8} />
          <use
            fill="#FFF"
            fillRule="nonzero"
            xlinkHref="#CuratedAlertsIllustration_svg__a"
          />
        </g>
      </g>
      <g transform="translate(0 18)" opacity={0.4}>
        <rect
          stroke="#576474"
          x={0.5}
          y={0.5}
          width={52.69}
          height={58.69}
          rx={2.4}
        />
        <path
          d="M27 17.33L14.39 28.25v12.07h9.87v-6.7a2.61 2.61 0 1 1 5.22 0v6.66h9.82V28.43L27 17.33z"
          fill="#576474"
          fillRule="nonzero"
        />
      </g>
      <g transform="translate(132 18)" opacity={0.4}>
        <rect
          stroke="#576474"
          x={0.5}
          y={0.5}
          width={52.69}
          height={58.69}
          rx={2.4}
        />
        <path
          d="M27 17.33L14.39 28.25v12.07h9.87v-6.7a2.61 2.61 0 1 1 5.22 0v6.66h9.82V28.43L27 17.33z"
          fill="#576474"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);

export default IllustrationCuratedAlerts;
