import { Modal } from "@wrstudios/components";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
  background: url("${({ background }) => background}") center center / cover
    no-repeat;
  position: absolute;
  top: 6rem;
  right: 0;
  bottom: 0;
  left: 0;

  @media (max-width: 576px) {
    padding: 0 1.2rem;
    background-image: none;
    background-color: white;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  width: 100%;
  max-width: 50.2rem;
  margin-top: 11.2rem;
  padding: 2.4rem 2.4rem 3.2rem;
  box-sizing: border-box;

  @media (min-width: 577px) {
    ${({ theme }) => theme.layers.modal};
  }

  @media (max-width: 576px) {
    margin-top: 2.4rem;
    padding: 1.6rem 1.6rem 2.4rem;
  }
`;

export const Title = styled.h1`
  ${({ theme }) => theme.text.h2}
`;

export const SubTitle = styled.p`
  ${({ theme }) => theme.text.medium}
  line-height: 2.4rem;
  margin: 1.2rem 0 0;

  strong {
    font-weight: 600;
  }
`;

export const Autocomplete = styled.div`
  margin-top: 2.4rem;
`;

export const ModalContent = styled(Modal.Content)`
  width: 100%;
  max-width: 80vw;
  padding: 0;
  border-radius: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    max-width: 100%;
  }
`;

export const ModalBody = styled(Modal.Body)`
  margin: 0;
  padding: 0;
`;

export const WatchVideoWrapper = styled.div`
  text-align: center;
`;

export const WatchVideo = styled.button`
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  margin-top: 2.4rem;
  color: ${({ theme }) => theme.prevColors.streamsBlue};
  text-decoration: none;
  cursor: pointer;

  svg {
    margin-left: 0.75rem;
    fill: ${({ theme }) => theme.prevColors.streamsBlue};
  }
`;
