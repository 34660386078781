import styled from "styled-components";

const paddingTop = `2.4rem`;
const tooltipArrowDimensions = 1.4;

export const Container = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 42.2rem;
  max-width: calc(100vw - 2.4rem);
  transform: translateX(-50%) translateY(100%);
  padding: ${paddingTop} 2.4rem 3rem;
  border-radius: 0.6rem;
  border: 0.1rem solid ${({ theme }) => theme.prevColors.greyLighter};
  box-shadow: ${({ theme }) => theme.shadows.overlay};
  background-color: ${({ theme }) => theme.prevColors.white};
  z-index: 8;

  @media (max-width: 1151px) {
    transform: translateX(-50%) translateY(calc(100% + 0.4rem));
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.page.twoColumn}px) {
    transform: translateX(0) translateY(calc(100% + 0.4rem));
    left: auto;
    right: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    transform: translateX(0) translateY(calc(100% + 0.4rem));
    left: auto;
    right: 0;
  }
`;

export const TooltipArrow = styled.div`
  position: absolute;
  left: 50%;
  width: ${tooltipArrowDimensions}rem;
  height: ${tooltipArrowDimensions}rem;
  border-radius: 0.2rem;
  border: 0.1rem solid ${({ theme }) => theme.prevColors.greyLighter};
  background-color: ${({ theme }) => theme.prevColors.white};
  transform: translateX(-50%) translateY(calc(-50% - ${paddingTop}))
    rotate(45deg);
  transform-origin: center;

  &::before {
    content: "";
    display: block;
    width: ${tooltipArrowDimensions + 0.1}rem;
    height: ${tooltipArrowDimensions + 0.1}rem;
    background-color: ${({ theme }) => theme.prevColors.white};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.page.twoColumn}px) {
    left: auto;
    right: 1.2rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    right: 0.2rem;
  }
`;

export const IconCloseLayout = styled.button`
  position: absolute;
  top: 0rem;
  right: 0rem;
  width: 4.4rem;
  height: 4.4rem;
  cursor: pointer;

  svg {
    position: absolute;
    top: 1rem;
    right: 1rem;
    fill: ${({ theme }) => theme.prevColors.blackLight};
  }
`;

export const CalloutLabel = styled.span`
  display: table;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.05rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.prevColors.white};
  background-color: ${({ theme }) => theme.prevColors.purple};
  margin-bottom: 0.9rem;
  border-radius: 0.3rem;
  padding: 0.3rem;
  text-transform: uppercase;
`;

export const CalloutHeading = styled.h4`
  ${({ theme }) => theme.text.h4}
  margin-bottom: 0.6rem;
`;

export const CalloutDescription = styled.span`
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.prevColors.greyDark};

  strong {
    font-weight: 600;
  }
`;
