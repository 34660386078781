import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

export default function ListingMini({ listing }) {
  return (
    <Container>
      <ListingPhoto src={listing.firstPhoto} />
      <DetailsLayout>
        <ListingAddress>{listing.address}</ListingAddress>
        <ListingDetails>
          {listing.beds && (
            <>
              {listing.beds} Bd
              <Bullet />
            </>
          )}
          {listing.baths && (
            <>
              {listing.baths} Ba
              <Bullet />
            </>
          )}
          {listing.sqft && (
            <>
              {listing.sqft} sqft
              <Bullet />
            </>
          )}
          {listing.built}
        </ListingDetails>
        <PriceAndStatus>
          <ListingPrice>{listing.priceFormatted}</ListingPrice>
          <Bullet />
          <ListingStatus status={listing.statusValue}>
            {listing.statusLabel}
          </ListingStatus>
        </PriceAndStatus>
      </DetailsLayout>
    </Container>
  );
}

ListingMini.propTypes = {
  listing: PropTypes.shape({
    firstPhoto: PropTypes.string
  })
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ListingPhoto = styled.img`
  width: 9.8rem;
  height: 6.3rem;
  object-fit: cover;
  border-radius: 0.4rem;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows.raised};
  margin-right: 1.2rem;
`;

const DetailsLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListingAddress = styled.span`
  ${({ theme }) => theme.text.medium};
  font-weight: 600;
  margin-bottom: 0.2rem;
`;

const ListingDetails = styled.span`
  ${({ theme }) => theme.text.small};
  margin-bottom: 0.2rem;
`;

const Bullet = styled.span`
  ${({ theme }) => theme.text.small};
  color: #8a929c;
  margin: 0 0.3rem;
  &::before {
    content: "•";
  }
`;

const PriceAndStatus = styled.div`
  display: flex;
  align-items: center;
`;

const ListingPrice = styled.span`
  ${({ theme }) => theme.text.medium};
  font-weight: 600;
`;

const ListingStatus = styled.span`
  ${({ theme }) => theme.text.small};
  color: ${({ theme, status }) => theme.prevColors.getStatusColor(status)};
`;
