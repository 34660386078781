import styled from "styled-components";

export const Container = styled.div`
  color: ${({ theme, active }) =>
    active ? theme.prevColors.streamsBlue : theme.prevColors.grey};
  cursor: pointer;

  &:hover,
  &:focus {
    svg {
      stroke: ${({ theme }) => theme.prevColors.streamsBlue};
    }
  }

  svg {
    stroke: ${({ theme, active }) =>
      active ? theme.prevColors.streamsBlue : theme.prevColors.grey};
  }
`;
