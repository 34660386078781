import { history } from "@wrstudios/utils";
import PropTypes from "prop-types";
import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { createStore } from "../../store";
import theme from "../../theme";

function AppProvider({ children, theme, store, history }) {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router history={history}>{children}</Router>
      </Provider>
    </ThemeProvider>
  );
}

AppProvider.defaultProps = {
  theme,
  store: createStore(),
  history
};

AppProvider.propTypes = {
  theme: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default AppProvider;
