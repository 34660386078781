import { AriaMessage, Link } from "@wrstudios/components";
import PropTypes from "prop-types";
import React, { Component, createRef } from "react";
import IconEllipsis from "../icons/IconEllipsis";
import StreamItemComment from "../stream/connected/StreamItemComment";
import StreamItemCommentForm from "../stream/connected/StreamItemCommentForm";
import StreamItemComments from "../stream/connected/StreamItemComments";
import StreamItemCommentSubscribers from "../stream/connected/StreamItemCommentSubscribers";
import StreamItemHide from "../stream/connected/StreamItemHide";
import StreamItemHides from "../stream/connected/StreamItemHides";
import StreamItemLike from "../stream/connected/StreamItemLike";
import StreamItemLikes from "../stream/connected/StreamItemLikes";
import StreamItemRecommends from "../stream/connected/StreamItemRecommends";
import StreamItemShare from "../stream/connected/StreamItemShare";
import StreamItemRecommend from "../stream/StreamItemRecommend";
import StreamItemRecommendModal from "../stream/StreamItemRecommendModal";
import ListingRequestShowing from "./connected/ListingRequestShowing";
import ListingControlsMenu from "./ListingControlsMenu";
import {
  Action,
  Actions,
  Button,
  Card,
  CardBody,
  CardFooter,
  CommentNotifiers,
  DOM,
  Header,
  LoginMessage,
  Price,
  PricePerSqft,
  Status
} from "./styled/listing-controls";

class ListingControls extends Component {
  ref = createRef();
  commentsFormRef = createRef();

  state = {
    isOpen: false,
    isRecommendModalOpen: this.props.shouldOpenRecommendModal,
    comment: ""
  };

  render() {
    const {
      streamId,
      streamItemId,
      statusValue,
      statusLabel,
      priceFormatted,
      pricePerSqft,
      domFormatted,
      isAgent,
      isClient,
      hasStreamItemId,
      isAuthed
    } = this.props;
    const {
      showingTimeUrl,
      newCmaSubjectUrl,
      newCmaPropertyUrl,
      newCmaFlyerUrl,
      newMlxListingUrl
    } = this.props;
    const { isOpen, isRecommendModalOpen } = this.state;
    const showAddComment = hasStreamItemId && isAuthed;
    const showRequestShowing = hasStreamItemId && isAuthed && !isAgent;
    const showShowingTime = isAgent && showingTimeUrl;

    return (
      <Card>
        <CardBody large>
          {!isOpen && (
            <>
              <Header>
                <Status statusValue={statusValue}>{statusLabel}</Status>
                {isAgent && isAuthed && (
                  <Action ref={this.ref} onClick={this.handleOnOpen}>
                    <IconEllipsis />
                    <AriaMessage>More listing options</AriaMessage>
                  </Action>
                )}
              </Header>
              <Price>
                {priceFormatted}
                {pricePerSqft && (
                  <PricePerSqft>
                    <strong> {pricePerSqft}</strong>/sqft
                  </PricePerSqft>
                )}
              </Price>
              <DOM showAddComment={showAddComment}>{domFormatted}</DOM>
              {showRequestShowing && (
                <ListingRequestShowing streamItemId={streamItemId} />
              )}
              {showAddComment && isAgent && (
                <Button
                  className="helix-btn helix-btn--primary"
                  onClick={this.handleCommentFieldFocus}>
                  Add a Comment
                </Button>
              )}
              {showShowingTime && (
                <a
                  href={showingTimeUrl}
                  target="_blank"
                  rel="noopener noreferrer">
                  <Button className="helix-btn helix-btn--secondary">
                    Schedule Showing
                  </Button>
                </a>
              )}
            </>
          )}
          {isOpen && (
            <ListingControlsMenu
              isFocused={isOpen}
              onClose={this.handleOnClose}
              newCmaSubjectUrl={newCmaSubjectUrl}
              newCmaPropertyUrl={newCmaPropertyUrl}
              newCmaFlyerUrl={newCmaFlyerUrl}
              newMlxListingUrl={newMlxListingUrl}
            />
          )}
        </CardBody>
        {showAddComment && (
          <>
            <CardFooter large>
              <Actions>
                {isAgent && !!streamId && (
                  <StreamItemRecommend streamItemId={streamItemId} />
                )}
                {isClient && <StreamItemLike streamItemId={streamItemId} />}
                <StreamItemHide streamItemId={streamItemId} />
                <StreamItemComment
                  streamItemId={streamItemId}
                  onClick={this.handleCommentFieldFocus}
                />
                <StreamItemShare streamItemId={streamItemId} />
              </Actions>
              <StreamItemRecommends streamItemId={streamItemId} />
              <StreamItemLikes streamItemId={streamItemId} />
              <StreamItemHides streamItemId={streamItemId} />
              <StreamItemComments streamItemId={streamItemId} />
            </CardFooter>
            <StreamItemCommentForm
              ref={this.commentsFormRef}
              streamItemId={streamItemId}
            />
            <CommentNotifiers>
              <StreamItemCommentSubscribers streamItemId={streamItemId} />
            </CommentNotifiers>
          </>
        )}
        {hasStreamItemId && !isAuthed && (
          <CardFooter large>
            <LoginMessage>
              <span onClick={this.handleClickLogin}>
                <Link to="/login">Login</Link>
              </span>
              &nbsp;to Like or Comment
            </LoginMessage>
          </CardFooter>
        )}
        <StreamItemRecommendModal
          streamItemId={streamItemId}
          isOpen={isRecommendModalOpen}
          onClose={() => this.setState({ isRecommendModalOpen: false })}
          onSent={() => this.setState({ isRecommendModalOpen: false })}
        />
      </Card>
    );
  }

  componentDidUpdate(_, prevState) {
    if (prevState.isOpen && !this.state.isOpen) {
      this.ref.current.focus();
    }
  }

  handleOnOpen = () => {
    this.setState({ isOpen: true });
  };

  handleOnClose = () => {
    this.setState({ isOpen: false });
  };

  handleClickLogin = () => {
    const { emitAction, currentPath } = this.props;
    emitAction({ type: "SET_FRIENDLY_REDIRECT", payload: currentPath });
  };

  handleCommentFieldFocus = () => {
    this.commentsFormRef.current.focus();
  };
}

ListingControls.propTypes = {
  streamId: PropTypes.string,
  streamItemId: PropTypes.string,
  listingId: PropTypes.string.isRequired,
  currentPath: PropTypes.string,
  statusValue: PropTypes.string,
  statusLabel: PropTypes.string,
  priceFormatted: PropTypes.string,
  pricePerSqft: PropTypes.string,
  domFormatted: PropTypes.string,
  showingTimeUrl: PropTypes.string,
  newCmaSubjectUrl: PropTypes.string,
  newCmaPropertyUrl: PropTypes.string,
  newCmaFlyerUrl: PropTypes.string,
  newMlxListingUrl: PropTypes.string,
  isAgent: PropTypes.bool.isRequired,
  isClient: PropTypes.bool.isRequired,
  hasStreamItemId: PropTypes.bool.isRequired,
  hasCommentsHash: PropTypes.bool.isRequired,
  isAuthed: PropTypes.bool.isRequired,
  emitAction: PropTypes.func
};

export default ListingControls;
