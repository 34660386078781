import styled from "styled-components";

export const Container = styled.button`
  cursor: pointer;

  &:hover,
  &:focus {
    svg {
      stroke: ${({ theme }) => theme.prevColors.red};
    }
  }

  svg {
    stroke: ${({ theme, active }) =>
      active ? theme.prevColors.red : theme.prevColors.grey};
    fill: ${({ theme, active }) =>
      active ? theme.prevColors.red : theme.prevColors.white};
  }
`;
