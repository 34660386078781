import styled from "styled-components";
import { Container as BaseInput } from "../../common/styled/input";
import { Container as BaseLabel } from "../../common/styled/label";

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const ContainerInner = styled.div`
  ${({ theme }) => theme.inputs.base};
  display: block;
  width: auto;
  padding: 0;
  border-color: ${({ theme, isInputFocused }) =>
    isInputFocused ? theme.prevColors.streamsBlue : theme.prevColors.greyLight};
`;

// * Notes: The Label and Input components are here in case you need to override default styling.
// * See components/stream/styled/stream-name for an example of this use case.
export const Label = styled(BaseLabel)``;

export const Input = styled(BaseInput)`
  flex-grow: 1;
  width: auto;
  padding: 0 3.2rem 0 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  position: relative;
  z-index: 1;

  :read-only {
    cursor: pointer;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
`;

export const InputContainerIcons = styled.div`
  svg {
    fill: ${({ theme }) => theme.prevColors.grey};
    transform: translate(0.6rem, -50%);
    position: absolute;
    top: 50%;
    right: 1.2rem;
  }
`;

export const Menu = styled.div`
  ${({ theme }) => theme.layers.overlay};
  margin-top: 1rem;
  overflow: hidden;
  position: absolute;
  z-index: 11;

  // * Notes This is a hack to get around the static height from react virtualized.
  .ReactVirtualized__Grid.ReactVirtualized__List {
    height: auto !important;
    max-height: 30rem !important;
  }
`;

export const Item = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.2rem;
  padding: 0.5rem 1.5rem;
  color: ${({ theme }) => theme.prevColors.greyMedium};
  background-color: ${({ theme }) => theme.prevColors.white};
  cursor: pointer;
  text-align: left;
  white-space: nowrap;

  &.is-highlighted,
  &.is-selected {
    background-color: ${({ theme }) => theme.prevColors.whiteDark};
  }

  &.is-selected {
    font-weight: 600;
  }
`;

export const Selected = styled.ul`
  ${({ theme }) => theme.removeScrollbar};
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: ${({ hasSelectedItems }) =>
    hasSelectedItems ? "0.4rem 0 0 1.2rem" : "0.6rem 0 0 1.2rem"};
  list-style: none;
`;

export const SelectedItem = styled.li`
  flex-shrink: 0;
  margin: ${({ hasSelectedItems }) =>
    hasSelectedItems ? "0 0.6rem 0.4rem 0" : "0 0.6rem 0.6rem 0"};
  padding: 0.2rem 0.5rem 0.2rem 0.9rem;
  color: ${({ theme }) => theme.prevColors.white};
  background: ${({ theme }) => theme.prevColors.streamsBlue};
  border-radius: 0.3rem;

  &:last-child {
    margin-right: 0;
  }
`;

export const SelectedItemRemove = styled.button`
  margin-left: 0.5rem;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline-color: ${({ theme }) => theme.prevColors.red};
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: ${({ theme }) => theme.prevColors.white};
  }
`;

export const SelectedItemInput = styled.li`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 0.6rem;
`;
