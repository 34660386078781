import styled from "styled-components";

export const Container = styled.div`
  margin: 3rem 0 2.3rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    margin: 1rem 0 2.3rem;
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1.2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    margin: -0.6rem;
  }
`;

export const Cell = styled.div`
  width: 25%;
  padding: 1.2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.twoColumn}px) {
    width: 50%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    padding: 0.6rem;
  }
`;

export const Stat = styled.button`
  ${({ theme }) => theme.layers.flat};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
  outline: none;
  cursor: ${({ disabled, isActive }) =>
    disabled || isActive ? "default" : "pointer"};
  border-color: ${({ theme, isActive }) =>
    isActive && theme.prevColors.streamsBlue};

  @media (max-width: ${({ theme }) => theme.breakpoints.page.twoColumn}px) {
    padding: 1.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    pading: 0.5rem;
    border-color: ${({ theme, isActive }) =>
      isActive ? theme.prevColors.streamsBlue : "transparent"};
  }

  &:focus {
    border-color: ${({ theme }) => theme.prevColors.streamsBlue};
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.8rem;
`;

export const Title = styled.h2`
  ${({ theme }) => theme.text.h2};
  font-weight: 700;
  margin-bottom: 0.2rem;
`;

export const Subtitle = styled.div`
  ${({ theme }) => theme.text.medium};
`;

export const Notice = styled.div`
  ${({ theme }) => theme.text.small};
  margin-top: 1.5rem;
  text-align: center;
`;

export const ShowAll = styled.button`
  ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.prevColors.streamsBlue};
  cursor: pointer;
`;
