import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 1.8rem 0 1.2rem;

  svg {
    flex-shrink: 0;
    margin-right: 0.8rem;
    stroke: ${({ theme }) => theme.prevColors.yellow};
    fill: transparent;
  }
`;
