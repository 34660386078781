import { Link } from "@wrstudios/components";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Input from "../../common/Input";
import Label from "../../common/Label";
import IconArrowLeft from "../../icons/IconArrowLeft";
import IconArrowRightLong from "../../icons/IconArrowRightLong";
import IconCASHorizontal from "../../icons/IconCASHorizontal";
import IconCheckCircleOutline from "../../icons/IconCheckCircleOutline";
import IconCloudStreamsVertical from "../../icons/IconCloudStreamsVertical";
import IconError from "../../icons/IconError";
import {
  Arrow,
  CASLink,
  CASLogo,
  Container,
  Content,
  Error,
  Form,
  FormGroup,
  Header,
  LearnMore,
  Logo,
  Nav,
  NavItem,
  NavItems,
  Reset,
  Submit,
  SubmitContainer,
  Success,
  Text,
  Title
} from "./styled/login-route";

function ResetPasswordRoute({
  isResettingPassword,
  resetPasswordError,
  resetPasswordFromEmail,
  newRailsApiToken,
  resetToken,
  emitAction
}) {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPasswordFromEmail(password, passwordConfirmation, resetToken);
  };

  const handleLogin = () => {
    emitAction({
      type: "LOGIN_AFTER_PASSWORD_RESET",
      payload: { token: newRailsApiToken }
    });
  };

  return (
    <Container>
      <Header>
        <CASLink
          href="https://cloudagentsuite.com/"
          target="_blank"
          rel="noopener noreferrer">
          <Arrow>
            <IconArrowLeft />
          </Arrow>
          <CASLogo>
            <IconCASHorizontal />
          </CASLogo>
        </CASLink>
        <LearnMore
          href="https://cloudagentsuite.com/tools/streams"
          target="_blank"
          rel="noopener noreferrer">
          Learn more about Cloud Streams
          <IconArrowRightLong />
        </LearnMore>
      </Header>
      <Content style={{ maxWidth: "40rem" }}>
        <Logo>
          <IconCloudStreamsVertical />
        </Logo>
        <Form onSubmit={handleSubmit}>
          <Title>Update Password</Title>
          <Text>Please enter a new password below</Text>
          {!!newRailsApiToken && (
            <FormGroup>
              <Success role="alert">
                <IconCheckCircleOutline />
                Sent! Check your email for instructions.
              </Success>
              <button
                type="button"
                onClick={handleLogin}
                className="helix-btn helix-btn--primary">
                Login
              </button>
            </FormGroup>
          )}
          {!newRailsApiToken && (
            <>
              <FormGroup>
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="password-confirmation">Confirm Password</Label>
                <Input
                  id="password-confirmation"
                  type="password"
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
              </FormGroup>
            </>
          )}
          {resetPasswordError && (
            <FormGroup>
              <Error role="alert">
                <IconError />
                {resetPasswordError}
              </Error>
            </FormGroup>
          )}
          <SubmitContainer>
            <Submit disabled={isResettingPassword}>
              {isResettingPassword ? "Resetting Password..." : "Reset Password"}
            </Submit>
          </SubmitContainer>
        </Form>
        <Reset>
          <Link to="/login">Return to Login</Link>
        </Reset>
      </Content>
      <Nav>
        <NavItems>
          <NavItem>
            <a
              href="https://cloudagentsuite.com/tos"
              target="_blank"
              rel="noopener noreferrer">
              Terms of Service
            </a>
          </NavItem>
          <NavItem>
            <a
              href="https://www.wrstudios.com/privacy_policy"
              target="_blank"
              rel="noopener noreferrer">
              Privacy
            </a>
          </NavItem>
          <NavItem>
            <a
              href="https://www.wrstudios.com/dmca_policy"
              target="_blank"
              rel="noopener noreferrer">
              DMCA
            </a>
          </NavItem>
        </NavItems>
      </Nav>
    </Container>
  );
}

ResetPasswordRoute.propTypes = {
  resetToken: PropTypes.string,
  isResettingPassword: PropTypes.bool.isRequired,
  resetPasswordError: PropTypes.string,
  newRailsApiToken: PropTypes.string,
  resetPasswordFromEmail: PropTypes.func.isRequired,
  emitAction: PropTypes.func.isRequired
};

export default ResetPasswordRoute;
