import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";

export const WhiteBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    display: none;
  }
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

export const ModalCard = styled.div`
  position: relative;
  width: ${({ fitContent }) => (fitContent ? "auto" : "60.8rem")};
  max-width: calc(100vw - 2.4rem);
  max-height: 100vh;
  border-radius: 0.6rem;
  ${({ theme }) => theme.layers.modal}
  border: 0.1rem solid ${({ theme }) => theme.prevColors.greyLighter};

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    width: 100%;
    max-width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0%;
    left: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: white;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 1.4rem;
  right: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  border: none;
  padding: 0;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.prevColors.blackLight};
  }
`;

export const ModalSection = styled.div`
  padding: 0 3.2rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.prevColors.greyLighter};
  background-color: ${({ theme, color }) => theme.colors[color || "white"]};

  &:first-of-type {
    border-radius: 0.6rem 0.6rem 0 0;
  }

  &:last-of-type {
    border-bottom: none;
    border-radius: 0 0 0.6rem 0.6rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    padding: 0 1.6rem;
  }
`;

export const ModalTitle = styled.h3`
  ${({ theme }) => theme.text.h3};
  line-height: 3.2rem;
  padding: 2.3rem 0 2.4rem;
`;

export const NameSection = styled.section`
  padding: 1.6rem 0 2.4rem;
`;

export const StreamNameLabel = styled.label`
  ${({ theme }) => theme.text.small}
  font-weight: 600;
`;

export const ModalSubtitle = styled.h4`
  ${({ theme }) => theme.text.h4}
  margin-bottom: 1.8rem;
`;

export const InvitesLayout = styled.div`
  padding: 2.4rem 0 2.6rem;
`;

export const UpdateLayout = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.6rem 0;
`;

export const InviteInfo = styled.p`
  ${({ theme }) => theme.text.medium}
  color: ${({ theme }) => theme.prevColors.greyMedium};
  margin: 1.6rem 0 0;
`;

export const MessageLayout = styled.div`
  display: flex;
  align-items: center;
  padding: 1.6rem 0;
`;

export const UpdateSettingsLayout = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.6rem 0;
`;

export const InvitationMessage = styled(TextareaAutosize)`
  ${({ theme }) => theme.inputs.base};
  min-height: 4rem;
  max-height: 30rem;
  resize: none;
`;

export const InviteButton = styled.button`
  flex-shrink: 0;
  margin-left: 1.6rem;
`;

export const UpdateSettingsButton = styled.button``;

/////////////////////////////////////////////////
// Prev Iteration of Components - BEGIN

export const AlertSection = styled.section`
  padding: 2.4rem;
`;

export const AlertOption = styled.button`
  ${({ theme }) => theme.layers.flat}
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2.4rem;
  margin-bottom: 1.8rem;
  border-width: 0.2rem;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const AlertTitle = styled.h5`
  ${({ theme }) => theme.text.h5}
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0.6rem;

  svg {
    margin-right: 1.4rem;
  }
`;

export const AlertDescription = styled.p`
  ${({ theme }) => theme.text.medium}
  color: ${({ theme }) => theme.prevColors.greyMedium};
  text-align: left;
  margin: 0;
`;

// Previous Iteration Components - END
/////////////////////////////////////////////////
