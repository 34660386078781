import { isMobilePhone } from "@wrstudios/utils";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Flash from "../common/Flash";
import FormGroup from "../common/FormGroup";
import { MessageBubble, TextMessages } from "./styled/user-phone-text-messages";
import {
  Button,
  Checkbox,
  Container,
  Errors,
  Input,
  Label,
  Link,
  Resend,
  Step,
  Text,
  Title
} from "./styled/user-phone-verify";

class UserPhoneVerify extends Component {
  state = {
    phone: this.props.phone || "",
    code: this.props.code || "",
    acceptTos: false
  };

  render() {
    const {
      fetchingVerifyPhone,
      fetchingVerifyCode,
      showVerifyPhone,
      showVerifyCode,
      showSuccess,
      verifyPhoneErrors,
      verifyCodeErrors,
      hasVerifyPhoneErrors,
      hasVerifyCodeErrors,
      agentName,
      modal
    } = this.props;
    const { phone, code, acceptTos } = this.state;
    const disableVerifyPhone =
      !isMobilePhone(phone) || fetchingVerifyPhone || !acceptTos;
    const disableVerifyCode = !code || fetchingVerifyCode;
    const phoneNumber = phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");

    return (
      <Container>
        {showVerifyPhone && (
          <Step modal={modal} onSubmit={this.handleSendVerificationCode}>
            <Title>Real-time Text Alerts</Title>
            <Text className="helix-mb-1">
              To begin receiving real-time SMS listing alerts, please enter your
              mobile phone number. We'll send you a verification code to
              confirm.
            </Text>
            <Label htmlFor="phone">Mobile Phone</Label>
            <Input
              name="phone"
              placeholder="000-000-0000"
              autoFocus
              value={phone}
              onChange={this.handleChange}
            />
            <Checkbox
              size="small"
              checked={this.state.acceptTos}
              onChange={this.handleAcceptTos}>
              I understand that by providing my mobile phone number, I am
              agreeing to receive listing alerts via text message. You can Reply
              HELP for help and STOP to be unsubscribed at any time. Message
              frequency varies. Message and data rates may apply. I agree to the
              Lone Wolf Real Estate Technology, Inc.{" "}
              <Link
                href="https://www.lwolf.com/about/msa"
                target="_blank"
                rel="noopener noreferrer">
                Terms of Service
              </Link>{" "}
              and its{" "}
              <Link
                href="https://www.lwolf.com/web-privacy-statement"
                target="_blank"
                rel="noopener noreferrer">
                Privacy Policy
              </Link>
            </Checkbox>
            <Button disabled={disableVerifyPhone}>
              {!fetchingVerifyPhone && "Request Verification Code"}
              {fetchingVerifyPhone && "Sending Verification Code..."}
            </Button>
            {hasVerifyPhoneErrors && (
              <Errors>
                {verifyPhoneErrors.map(({ message }, index) => (
                  <FormGroup key={index}>
                    <Flash>{message}</Flash>
                  </FormGroup>
                ))}
              </Errors>
            )}
          </Step>
        )}
        {showVerifyCode && (
          <Step modal={modal} onSubmit={this.handleVerifyCode}>
            <Title>Enter Verification Code</Title>
            <Text>
              Check your mobile phone {phoneNumber} for a text message like this
              one:
            </Text>
            <TextMessages>
              <MessageBubble>
                <div>
                  You have requested to receive real-time listing alerts via
                  Cloud Streams.
                </div>
                <div className="helix-mt-3">
                  Your verification code is XXXX.
                </div>
              </MessageBubble>
            </TextMessages>
            <Text>Enter the verification code you received below:</Text>
            <Label htmlFor="code">Verification Code</Label>
            <Input
              name="code"
              placeholder="0000"
              autoFocus
              value={code}
              onChange={this.handleChange}
            />
            <div className="helix-d-flex">
              <Button
                disabled={disableVerifyCode}
                className="helix-btn helix-btn--primary">
                {!fetchingVerifyCode && "Verify Code"}
                {fetchingVerifyCode && "Verifying Code..."}
              </Button>
              <Resend
                type="button"
                className="helix-btn helix-btn--ghost helix-ml-2"
                disabled={disableVerifyPhone}
                onClick={this.handleSendVerificationCode}>
                Send new code
              </Resend>
            </div>
            {hasVerifyCodeErrors && (
              <Errors>
                {verifyCodeErrors.map(({ message }, index) => (
                  <FormGroup key={index}>
                    <Flash>{message}</Flash>
                  </FormGroup>
                ))}
              </Errors>
            )}
          </Step>
        )}
        {showSuccess && (
          <Step modal={modal} onSubmit={this.handleOnClose}>
            <Title>Phone Verified</Title>
            <Text>
              Now that you’ve verified you phone, when we find a new property
              that matches your criteria, you’ll receive a message like this:
            </Text>
            <TextMessages>
              <MessageBubble>
                <div className="helix-mb-2">New Listing from {agentName}:</div>
                <div>123 Address Street, City</div>
                <div>$Price — 4 Beds • 3.0 Baths • 2000 sqft</div>
                <div>___</div>
                <div>Sent via Cloud Streams</div>
                <div>Reply HELP for help or STOP to cancel alerts.</div>
                <div className="helix-mt-2">
                  https://cloudstreams.net/stream_items/:id
                </div>
              </MessageBubble>
            </TextMessages>
            <Button className="helix-mt-6">Done</Button>
          </Step>
        )}
      </Container>
    );
  }

  componentDidMount() {
    if (this.props.showVerifyCode && isMobilePhone(this.state.phone)) {
      this.props.sendUserPhoneVerificationCode({
        phoneNumber: this.state.phone
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.fetchedVerifyCode && this.props.fetchedVerifyCode) {
      this.props.onSuccess(this.state.phone, this.state.code);
    }
  }

  componentWillUnmount() {
    this.props.resetUserPhoneVerification();
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAcceptTos = (e) => {
    this.setState({ acceptTos: e.target.checked });
  };

  handleSendVerificationCode = (e) => {
    e.preventDefault();

    this.props.sendUserPhoneVerificationCode({
      phoneNumber: this.state.phone
    });
  };

  handleVerifyCode = (e) => {
    e.preventDefault();

    this.props.verifyUserPhoneVerificationCode({
      phoneNumber: this.state.phone,
      secret: this.props.phoneVerificationCode,
      code: this.state.code
    });
  };

  handleOnClose = (e) => {
    e.preventDefault();

    this.props.onClose();
  };
}

UserPhoneVerify.propTypes = {
  phone: PropTypes.string,
  modal: PropTypes.bool,
  phoneVerificationCode: PropTypes.string.isRequired,
  fetchingVerifyPhone: PropTypes.bool.isRequired,
  fetchingVerifyCode: PropTypes.bool.isRequired,
  fetchedVerifyCode: PropTypes.bool.isRequired,
  showVerifyPhone: PropTypes.bool.isRequired,
  showVerifyCode: PropTypes.bool.isRequired,
  showSuccess: PropTypes.bool.isRequired,
  hasVerifyPhoneErrors: PropTypes.bool.isRequired,
  hasVerifyCodeErrors: PropTypes.bool.isRequired,
  verifyPhoneErrors: PropTypes.array.isRequired,
  verifyCodeErrors: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  sendUserPhoneVerificationCode: PropTypes.func.isRequired,
  verifyUserPhoneVerificationCode: PropTypes.func.isRequired,
  resetUserPhoneVerification: PropTypes.func.isRequired
};

UserPhoneVerify.defaultProps = {
  onSuccess: function () {}
};

export default UserPhoneVerify;
