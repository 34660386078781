import { Link, Modal } from "@wrstudios/components";
import { emailIsValid } from "@wrstudios/utils";
import PropTypes from "prop-types";
import React, { Component, createRef } from "react";
import Input from "../common/Input";
import Label from "../common/Label";
import {
  Button,
  Cell,
  Divider,
  Error,
  Form,
  Grid,
  ModalBody,
  ModalContent,
  ModalFooter,
  Text
} from "./styled/client-card-add-modal";

class ClientInviteModal extends Component {
  nameRef = createRef();

  state = {
    name: "",
    email: "",
    message: ""
  };

  render() {
    const isDisabled =
      (this.props.isInvitingClient && !this.props.inviteClientError) ||
      !this.state.name ||
      !this.isValidEmail();

    return (
      <Modal onClose={this.props.onClose}>
        <Form onSubmit={this.handleSubmit}>
          <ModalContent>
            <Modal.Header>
              Add a Client
              <Modal.Close />
            </Modal.Header>
            <ModalBody>
              <Text>
                Adding a client will invite them to create their own Stream. If
                you prefer to do that for them,{" "}
                <Link to="/streams/new">Create a Stream</Link> instead.
              </Text>
              <Divider />
              <Grid>
                <Cell>
                  <Label htmlFor="name">Name</Label>
                  <Input
                    ref={this.nameRef}
                    id="name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                </Cell>
                <Cell>
                  <Label htmlFor="email">Email</Label>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                </Cell>
              </Grid>
              {this.props.inviteClientError && (
                <Error>
                  There was a problem while inviting your client. Please try
                  again.
                </Error>
              )}
            </ModalBody>
            <ModalFooter>
              <Grid>
                <Cell>
                  <Input
                    name="message"
                    placeholder="Add message (optional)"
                    value={this.state.message}
                    onChange={this.handleChange}
                  />
                </Cell>
                <Cell>
                  <Button
                    disabled={isDisabled}
                    className="helix-btn helix-btn--primary">
                    {this.props.isInvitingClient &&
                    !this.props.inviteClientError
                      ? "Sending Invite"
                      : "Send Invite"}
                  </Button>
                </Cell>
              </Grid>
            </ModalFooter>
          </ModalContent>
        </Form>
      </Modal>
    );
  }

  componentDidMount() {
    // Bypass focus trap
    setTimeout(() => {
      this.nameRef.current.focus();
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isInvitingClient &&
      !this.props.isInvitingClient &&
      !this.props.inviteClientError
    ) {
      this.props.onClose();
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.state.name || !this.isValidEmail()) return;

    this.props.inviteClient({
      agentId: this.props.agentId,
      name: this.state.name,
      email: this.state.email,
      message: this.state.message
    });
  };

  isValidEmail = () => {
    const hasValidEmail = emailIsValid(this.state.email);
    const emailIsCurrentUserEmail =
      this.state.email === this.props.currentUserEmail;
    const emailIsIncludedInCurrentClientList = this.props.clientEmails.includes(
      this.state.email
    );

    return (
      hasValidEmail &&
      !emailIsCurrentUserEmail &&
      !emailIsIncludedInCurrentClientList
    );
  };
}

ClientInviteModal.propTypes = {
  agentId: PropTypes.string.isRequired,
  currentUserEmail: PropTypes.string.isRequired,
  clientEmails: PropTypes.array.isRequired,
  inviteClient: PropTypes.func.isRequired,
  isInvitingClient: PropTypes.bool.isRequired,
  inviteClientError: PropTypes.object,
  onClose: PropTypes.func.isRequired
};

export default ClientInviteModal;
