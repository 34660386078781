// ! DO NOT UPDATE THIS FILE
// ! THIS FILE IS ONLY USED AS A BACKWARDS COMPATABILITY MECHANISM
// ! IF YOU NEED TO MAKE A CHANGE, CONSIDER USING `@wrstudios/theme` INSTEAD

export const white = "#fff";
export const whiteDark = "#f0f2f4";
export const black = "#000";
export const blackLight = "#89929D";

export const red = "#EC5252";
export const teal = "#00C9C7";
export const gold = "#BAAD81";
export const green = "#00CA72";
export const yellow = "#F6A807";
export const purple = "#9B52EC";
export const purpleDark = "#7C8AEF";
export const magenta = "#E352EC";

export const blueLight = "#5494d8";
export const bluePowder = "#ECF3FC";
export const blueMedium = "#2684E8";
export const blueDeep = "#5265EC";
export const blueDark = "#3A7ABE";
export const blueVeryDark = "#23527C";

export const grey = "#576474";
export const greyDark = "#233040";
export const greyMedium = "#566373";
export const greyMidLight = "#929498";
export const greyLight = "#C5C9D1";
export const greyLighter = "#E1E5E9";
export const greyLightest = "#FAFAFA";

export const pastelYellow = "#FDEECD";
export const pastelGreen = "#CCF4E3";
export const pastelBlue = "#D8E4F2";
export const pastelRed = "#FBDCDC";

export const streamsBlue = "#236847";
export const cmaGreen = "#00AB6B";
export const mlxBlue = "#2684E8";
export const attractRed = "#FD5154";

const initialsColors = [red, teal, green, yellow, purple, magenta, blueMedium];

export function getInitialsColor(name = "") {
  const charCodeTotal = name
    .split("")
    .reduce((total, char) => total + char.charCodeAt(), 0);
  const colorIndex = charCodeTotal % initialsColors.length;
  return initialsColors[colorIndex];
}

export function getStatusColor(statusValue) {
  switch (statusValue) {
    case "active":
      return green;
    case "backup":
      return teal;
    case "pending":
      return yellow;
    case "closed":
      return red;
    // TODO: enumerate other status cases (waiting to see if Forge API exposes this as an Enum on the ListingResultType > status field)
    default:
      return blackLight; // defaults to inactive color
  }
}
