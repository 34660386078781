import styled from "styled-components";

export const Container = styled.div`
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    flex-direction: column;
  }
`;

export const Form = styled.form`
  width: 31.8rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.twoColumn}px) {
    width: 50%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    width: 100%;
  }
`;

export const Avatar = styled.div`
  flex-shrink: 0;
  width: 26.6rem;
  padding: 2.5rem 0 0 3.5rem;
  margin-left: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    order: -1;
    margin: 0 auto 2rem;
    padding: 0;
  }
`;

export const Phone = styled.div`
  display: flex;
  align-items: center;
`;

export const PhoneVerify = styled.button`
  margin-left: 1rem;
`;
