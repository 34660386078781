import { Modal as BaseModal } from "@wrstudios/components";
import { breakpoints, colors } from "@wrstudios/theme";
import styled from "styled-components";
import BaseFormGroup from "../../common/FormGroup";
import BaseInput from "../../common/Input";
import BaseLabel from "../../common/Label";
import BaseTextarea from "../../common/Textarea";

export const Request = styled.button`
  display: block;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.6rem;
  margin: 1.2rem 0 0.8rem;
  padding: 1.2rem;
`;

export const SendRequest = styled.button`
  justify-content: center;
  font-weight: 600;
  padding: 0.8rem 1.6rem;

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;

export const BackToListing = styled.button`
  justify-content: center;
  font-weight: 600;
  padding: 0.8rem 1.6rem;
  border-width: 0.1rem;

  width: 100%;
`;

export const ModalContent = styled(BaseModal.Content)`
  width: 100%;
  max-width: 52.3rem;
`;

export const ModalHeader = styled(BaseModal.Header)`
  margin-bottom: 1.6rem;
`;

export const ModalBody = styled(BaseModal.Body)`
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
`;

export const FooterAction = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const FormGroup = styled(BaseFormGroup)`
  margin-bottom: 1.6rem;
`;

export const Label = styled(BaseLabel)`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  margin-bottom: 0.4rem;
  color: ${colors.gray[900]};

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 1.5rem;
  }
`;

export const Input = styled(BaseInput)`
  border: 0.1rem solid ${colors.gray[500]};
`;

export const Textarea = styled(BaseTextarea)`
  font-size: 1.6rem;
  border: 0.1rem solid ${colors.gray[500]};
`;

export const Text = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 2.4rem 0;
  color: ${colors.gray[900]};

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 1.5rem;
  }
`;

export const Error = styled(Text)`
  margin: 1.6rem 0 0;
  color: ${colors.red[500]};
`;
