import { ListingScrollToTop } from "@wrstudios/components";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { withTheme } from "styled-components";
import ListingControls from "./connected/ListingControls";
import ListingDescription from "./connected/ListingDescription";
import ListingDetails from "./connected/ListingDetails";
import ListingDisclaimer from "./connected/ListingDisclaimer";
import ListingFullscreenMedia from "./connected/ListingFullscreenMedia";
import ListingHeader from "./connected/ListingHeader";
import ListingHighlights from "./connected/ListingHighlights";
import ListingHistory from "./connected/ListingHistory";
import ListingMlsDisclaimer from "./connected/ListingMlsDisclaimer";
import ListingSchools from "./connected/ListingSchools";
import ListingShowings from "./connected/ListingShowings";
import ListingSlider from "./connected/ListingSlider";
import {
  Container,
  Content,
  Disclaimer,
  Grid,
  Group,
  Sidebar
} from "./styled/listing";

class Listing extends Component {
  render() {
    const {
      streamId,
      streamItemId,
      listingId,
      isAuthed,
      isAgent,
      hasListingData,
      pageTitle,
      history,
      isFullscreenMediaOpen,
      theme
    } = this.props;

    if (!hasListingData) {
      return null;
    }

    if (isFullscreenMediaOpen) {
      return <ListingFullscreenMedia listingId={listingId} />;
    }

    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <ListingHeader
          streamItemId={streamItemId}
          listingId={listingId}
          onBackButtonClick={() => history.push(`/streams/${streamId}`)}
        />
        <ListingSlider listingId={listingId} />
        <Container>
          <Grid>
            <Content>
              <Group>
                <ListingHighlights listingId={listingId} />
              </Group>
              <Group>
                <ListingDescription listingId={listingId} />
              </Group>
              {isAuthed && isAgent && (
                <Group>
                  <ListingShowings listingId={listingId} />
                </Group>
              )}
              <Group>
                <ListingDetails listingId={listingId} />
              </Group>
              <Group>
                <ListingSchools listingId={listingId} />
              </Group>
              <Group>
                <ListingHistory listingId={listingId} />
              </Group>
              <Group>
                <ListingMlsDisclaimer />
              </Group>
            </Content>
            <Sidebar>
              <ListingControls
                streamItemId={streamItemId}
                listingId={listingId}
              />
              <Disclaimer>
                <ListingDisclaimer
                  listingId={listingId}
                  mlsnumColor={theme.colors.brand}
                />
                <ListingScrollToTop color={theme.colors.brand} />
              </Disclaimer>
            </Sidebar>
          </Grid>
        </Container>
      </>
    );
  }

  componentDidMount() {
    this.props.setAppHeaderSticky(false);
    if (!this.props.hasListingData && this.props.mlsConfigsLoaded) {
      this.getListingData();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.mlsConfigsLoaded &&
      this.props.mlsConfigsLoaded &&
      !this.props.hasListingData
    ) {
      this.getListingData();
    }
  }

  componentWillUnmount() {
    this.props.setAppHeaderSticky(true);
  }

  getListingData() {
    this.props.getForgeListing(this.props.listingId);
  }
}

Listing.propTypes = {
  streamId: PropTypes.string,
  streamItemId: PropTypes.string,
  listingId: PropTypes.string.isRequired,
  hasListingData: PropTypes.bool.isRequired,
  pageTitle: PropTypes.string,
  isAuthed: PropTypes.bool,
  isAgent: PropTypes.bool,
  mlsConfigsLoaded: PropTypes.bool.isRequired
};

export default withRouter(withTheme(Listing));
