import styled, { css } from "styled-components";
import NavLink from "../../common/NavLink";

export const Container = styled.nav`
  position: ${({ isSticky }) => (isSticky ? "sticky" : "relative")};
  top: 0;
  z-index: 1000;

  // The agent uses the new LW header and the client doesn't
  ${({ isAgent }) =>
    isAgent
      ? css`
          .label {
            font-size: 1.4rem !important;
          }

          .description {
            font-size: 1.2rem !important;
          }

          .helix-unified-nav__nav-button.helix-unified-nav__nav-button-user:not(
              :disabled
            ) {
            cursor: pointer;
          }
        `
      : css`
          display: flex;
          flex-shrink: 0;
          align-items: center;
          width: 100%;
          height: 6rem;
          padding: 1.2rem ${({ theme }) => theme.page.padding}rem;
          border-bottom: 0.1rem solid
            ${({ theme }) => theme.prevColors.greyLighter};
          background-color: ${({ theme }) => theme.prevColors.white};
          box-shadow: ${({ theme }) => theme.shadows.sticky};

          @media (max-width: ${({ theme }) =>
              theme.breakpoints.page.oneColumn}px) {
            padding: 1.2rem ${({ theme }) => theme.page.padding / 2}rem;
          }
        `}
`;

export const Cell = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${getCellAlignment};
  align-items: center;
`;

export const AppSwitcherContainer = styled.div`
  margin-left: 1.8rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    margin-left: 1.2rem;
  }
`;

export const Navigation = styled.div`
  display: flex;
  align-items: center;
`;

export const NavigationLink = styled(NavLink)`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2.1rem;
  margin: 0 3.2rem;
  color: ${({ theme }) => theme.prevColors.grey};

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    margin: 0 0.8rem;
  }
`;

function getCellAlignment({ align }) {
  switch (align) {
    case "left":
      return "flex-start";
    case "center":
      return "center";
    case "right":
      return "flex-end";
    default:
      console.warn(`Unknow alignment ${align}`);
      return "flex-start";
  }
}
