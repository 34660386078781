import PropTypes from "prop-types";
import React, { Component } from "react";
import { timer } from "../../../utils/timer";
import Flash from "../../common/Flash";
import FormGroup from "../../common/FormGroup";
import Input from "../../common/Input";
import Label from "../../common/Label";
import { Button, Form } from "./styled/settings-route";

class SettingsRoutePassword extends Component {
  state = {
    password: "",
    passwordConfirm: ""
  };

  render() {
    const { isFetching, hasFetched, errors } = this.props;
    const { password, passwordConfirm } = this.state;
    const isDisabled = this.getIsDisabled();

    const isLWManaged = this.props.hasLwaId;

    return (
      <div className="helix-d-flex helix-flex-direction--column helix-gap-6">
        <div className="helix-d-flex helix-flex-direction--column helix-gap-4">
          <h4 className="helix-display-x-small">Lone Wolf Account</h4>
          {isLWManaged ? (
            <>
              <p className="helix-body">
                You are using your Lone Wolf login to sign into this account. To
                manage your password and app connections go to your Lone Wolf
                account.
              </p>
              <a
                href={`${process.env.REACT_APP_LW_PLATFORM_URL}/account/manage`}
                className="helix-btn helix-btn--primary helix-100-percent helix-text--center">
                Manage Lone Wolf Account
              </a>
            </>
          ) : (
            <>
              <p className="helix-body">
                A Lone Wolf Account allows you to sign into all of your Lone
                Wolf products with a single password, and switch between them
                seamlessly.
              </p>
              <a
                href={`${process.env.REACT_APP_API_URL}/connect/lone_wolf?token=${this.props.casJwt}`}
                className="helix-btn helix-btn--primary helix-100-percent helix-text--center">
                Create or Connect a Lone Wolf Account
              </a>
            </>
          )}
        </div>
        {!isLWManaged && (
          <>
            <div
              className="helix-w-100-percent helix-bg-gray-200"
              style={{ height: 1 }}
            />
            <Form onSubmit={this.handleOnSubmit}>
              <FormGroup>
                <Label htmlFor="new-password">New Password</Label>
                <Input
                  id="new-password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={this.handleOnChange}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="confirm-password">Confirm New Password</Label>
                <Input
                  id="confirm-password"
                  name="passwordConfirm"
                  type="password"
                  value={passwordConfirm}
                  onChange={this.handleOnChange}
                />
              </FormGroup>
              {errors.map(({ message }, index) => (
                <FormGroup key={index}>
                  <Flash status="error">{message}</Flash>
                </FormGroup>
              ))}
              {hasFetched && (
                <FormGroup>
                  <Flash status="success">Password updated</Flash>
                </FormGroup>
              )}
              <Button
                disabled={isDisabled}
                className="helix-btn helix-btn--primary">
                {!isFetching && "Save Password"}
                {isFetching && "Saving Password"}
              </Button>
            </Form>
          </>
        )}
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    this.handleOnSuccess(prevProps);
  }

  componentWillUnmount() {
    this.props.resetUserPasswordNetwork();
  }

  handleOnChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();

    this.props.updateUserPassword({
      password: this.state.password,
      passwordConfirm: this.state.passwordConfirm
    });
  };

  handleOnSuccess = (prevProps) => {
    if (!prevProps.hasFetched && this.props.hasFetched) {
      this.setState({ password: "", passwordConfirm: "" }, async () => {
        await timer(2000);

        this.props.resetUserPasswordNetwork();
      });
    }
  };

  getIsDisabled = () => {
    const hasPassword = !!this.state.password;
    const hasPasswordConfirm = !!this.state.passwordConfirm;
    const isPasswordAndPasswordConfirmDifferent =
      this.state.password !== this.state.passwordConfirm;

    return (
      this.props.isFetching ||
      !hasPassword ||
      !hasPasswordConfirm ||
      isPasswordAndPasswordConfirmDifferent
    );
  };
}

SettingsRoutePassword.propTypes = {
  hasLwaId: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  hasFetched: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired
    })
  ).isRequired,
  updateUserPassword: PropTypes.func.isRequired,
  resetUserPasswordNetwork: PropTypes.func.isRequired
};

export default SettingsRoutePassword;
