import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 5.8rem;
  background-color: ${({ theme }) => theme.prevColors.white};
  border: 0.1rem solid ${({ theme }) => theme.prevColors.whiteDark};
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const Grid = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  padding: 0.9rem 0.5rem;
  position: relative;

  &:first-child {
    flex-grow: ${({ hideSaveButton }) => (hideSaveButton ? 1 : 0)};
    min-width: ${({ hideSaveButton }) => (hideSaveButton ? 0 : "32rem")};
  }
`;

export const FiltersButton = styled.button`
  display: none;
  position: relative;

  ${({ isVisible }) =>
    isVisible &&
    css`
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      color: ${({ theme, isActive }) =>
        isActive ? theme.prevColors.streamsBlue : theme.prevColors.grey};

      svg {
        fill: ${({ theme, isActive }) =>
          isActive ? theme.prevColors.streamsBlue : theme.prevColors.grey};
      }
    `}

  ${({ theme, shouldShowOnboarding }) =>
    shouldShowOnboarding &&
    css`
      &::after {
        content: "";
        position: absolute;
        width: calc(100% + 0.4rem);
        height: calc(100% + 0.4rem);
        display: block;
        border-radius: 0.4rem;
        padding: 0.2rem 0.4rem;
        transform: translateX(calc(-0.4rem * 2));
        box-sizing: content-box;
        border: 0.2rem solid ${theme.prevColors.purple};
      }
    `};
`;

export const FiltersButtonLabel = styled.span`
  ${({ theme }) => theme.text.medium};
  margin-left: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    display: none;
  }
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;

  ${({ shouldShowOnboarding, theme }) =>
    shouldShowOnboarding &&
    css`
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border-radius: 0.6rem;
      box-shadow: 0 0 0 0.2rem ${theme.prevColors.purple} inset;
    `};
`;

export const Filter = styled.div`
  padding: 0 0.5rem;
`;

export const SaveButtonLayout = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0.8rem;
  }
`;

export const SaveButton = styled.button`
  display: inline-flex;
  align-items: center;
  border: 0.1rem solid transparent;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  max-height: 3.8rem;

  svg {
    fill: ${({ theme }) => theme.prevColors.white};
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }
`;

export const MobileStickyFooter = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.2rem;
  background-color: white;
  box-shadow: ${({ theme }) => theme.shadows.overlayReverse};

  ${SaveButton} {
    width: 100%;
    display: inline-flex;
    justify-content: center;
  }
`;
