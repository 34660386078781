import styled from "styled-components";

export const DummyAvatar = styled.div`
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.prevColors.greyLighter};
  margin-right: 1.6rem;
`;

export const DummyText = styled.div`
  width: 75%;
  height: 1.4rem;
  background-color: ${({ theme }) => theme.prevColors.greyLighter};
`;

export const DummyPhoto = styled.div`
  width: 6.4rem;
  height: 4.8rem;
  margin-left: auto;
  background-color: ${({ theme }) => theme.prevColors.greyLighter};
`;
