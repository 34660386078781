import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const Button = styled.button`
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 0.8rem 1.2rem;
  border-radius: 0.4rem;
  border: 0.2rem solid transparent;
  cursor: pointer;
  font-weight: ${({ hasSelectedValues }) =>
    hasSelectedValues ? "600" : "400"};
  color: ${({ theme, hasSelectedValues }) =>
    hasSelectedValues ? theme.prevColors.streamsBlue : theme.prevColors.grey};
  border-width: 0.1rem;
  border-color: ${({ theme }) => theme.prevColors.greyLight};
  background-color: ${({ theme }) => theme.prevColors.white};
  white-space: nowrap;
`;
