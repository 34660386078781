import styled from "styled-components";
import { default as BaseCheckbox } from "../Checkbox";

export const Container = styled.div`
  position: relative;
`;

export const TriggerButton = styled.button`
  ${({ theme }) => theme.inputs.base};
  display: inline-block;
  border-color: ${({ theme, isOpen }) =>
    isOpen ? theme.prevColors.streamsBlue : theme.prevColors.greyLight};
  text-align: left;
  cursor: pointer;
`;

export const TriggerLabel = styled.span`
  ${({ theme }) => theme.noWrap};
`;

export const TriggerPointer = styled.span`
  svg {
    fill: ${({ theme }) => theme.prevColors.grey};
    transform: translate(0.6rem, -50%);
    position: absolute;
    top: 50%;
    right: 1.2rem;
  }
`;

export const Menu = styled.div`
  ${({ theme }) => theme.layers.overlay};
  min-width: 100%;
  margin-top: 1rem;
  overflow: hidden;
  position: absolute;
  z-index: 11;
`;

export const Options = styled.div`
  max-height: 30rem;
  overflow: auto;
`;

export const Option = styled.div`
  background-color: ${({ theme, isHighlighted }) =>
    isHighlighted ? theme.prevColors.whiteDark : theme.prevColors.white};
`;

export const Checkbox = styled(BaseCheckbox)`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.2rem;
  width: 100%;
  padding: 0.5rem 1.5rem;
  color: ${({ theme }) => theme.prevColors.greyMedium};
  cursor: pointer;
  text-align: left;
  white-space: nowrap;
`;
