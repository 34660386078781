import styled from "styled-components";

export const Container = styled.label`
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.8rem;
  margin-bottom: 0.6rem;
  color: ${({ theme }) => theme.prevColors.greyDark};

  &[for] {
    cursor: pointer;
  }
`;
