import styled from "styled-components";
import BaseFlash from "../../common/Flash";
import BaseInput from "../../common/Input";

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
`;

export const SignupCardForm = styled.div`
  ${({ theme }) => theme.layers.modal}
  position: relative;
  border-radius: 1.2rem;
  width: 44rem;
  padding: 3.2rem;
`;

export const CardHeading = styled.h1`
  ${({ theme }) => theme.text.h2}
  margin-bottom: 1.2rem;
`;

export const CardInstructions = styled.span`
  display: block;
  font-size: 1.4rem;
  line-height: 24px;
  color: ${({ theme }) => theme.prevColors.greyDark};
  margin-bottom: 2.6rem;
`;

export const Flash = styled(BaseFlash)`
  margin-bottom: 1.8rem;
`;

export const Input = styled(BaseInput)`
  margin-bottom: 1.8rem;
`;

export const AlertsPanel = styled.div`
  width: 100%;
  margin-bottom: 2.4rem;
  border: 0.1rem solid ${({ theme }) => theme.prevColors.greyLighter};
  border-radius: 0.4rem;
  padding: 1.6rem;
`;

export const AlertsPanelHeading = styled.h3`
  ${({ theme }) => theme.text.h5}
  margin-bottom: 1.4rem;
`;

export const AlertPreferences = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 2.4rem;
  }
`;

export const Button = styled.button`
  display: block;
  margin: 0.6rem auto 0;
  align-self: center;
`;

export const PhoneVerifyLayout = styled.div`
  position: fixed;
  margin: 0;
  background-color: ${({ theme }) => theme.prevColors.white};
  border-radius: 1.2rem;
  z-index: 101;
`;

export const Actions = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.4rem 1.6rem;
  text-align: center;
  background-color: ${({ theme }) => theme.prevColors.whiteDark};
  border-radius: 0 0 0.6rem 0.6rem;
`;

export const Back = styled.button`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
  padding: 0.8rem 0;
  color: ${({ theme }) => theme.prevColors.streamsBlue};
  background-color: transparent;
  border: 0;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.prevColors.streamsBlue};
  }
`;
