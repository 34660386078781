import { Menu as BaseMenu, Link } from "@wrstudios/components";
import { colors } from "@wrstudios/theme";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0.3rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const Grid = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -0.7rem;
`;

export const Cell = styled.div`
  flex-grow: ${({ grow }) => (grow ? 1 : 0)};
  padding: 0 0.7rem;
  overflow: hidden;
`;

export const Details = styled.div`
  width: calc(100% - 0.7rem);
`;

const Truncate = styled.span`
  flex-grow: 1;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Name = styled(Truncate)`
  display: block;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
  margin: 0;
  color: ${({ theme }) => theme.prevColors.greyDark};
`;

export const Timestamp = styled(Truncate)`
  display: block;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: ${({ theme }) => theme.prevColors.greyMedium};
`;

export const Pending = styled(Truncate)`
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: ${({ theme }) => theme.prevColors.greyMedium};
`;

export const Share = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1.2rem;
  line-height: 1.8rem;
`;

export const SharedClient = styled(Link)`
  display: flex;
  align-items: center;
`;

export const SharedClientImage = styled.span`
  display: inline-block;
  margin-right: ${({ hasMultipleSharedClients }) =>
    hasMultipleSharedClients ? 0 : 0.8}rem;
  background-image: ${({ hasMultipleSharedClients }) =>
    hasMultipleSharedClients && "none"};
`;

export const SharedClientName = styled.span`
  ${({ theme }) => theme.noWrap};
  display: inline-block;
  padding-right: 0.8rem;
  color: ${({ theme }) => theme.prevColors.greyDark};
`;

export const SharedClientResend = styled.button`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.8rem;
  cursor: pointer;
  color: ${({ theme }) => theme.prevColors.streamsBlue};

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    color: ${({ theme }) => theme.prevColors.blueMedium};
  }

  &:focus {
    outline: none;
    color: ${({ theme }) => theme.prevColors.blueMedium};
  }
`;

export const MenuContainer = styled.div`
  margin-left: auto;
  cursor: pointer;
  color: ${({ theme }) => theme.prevColors.streamsBlue};
`;

export const MenuButton = styled(BaseMenu.Button)`
  svg {
    width: 2rem;
    height: 2rem;
    fill: ${({ theme }) => theme.prevColors.streamsBlue};
  }
`;

export const MenuItemDelete = styled(BaseMenu.Item)`
  &,
  &:hover:not(:disabled),
  &:active:not(:disabled),
  &:focus:not(:disabled) {
    color: ${colors.red[500]};
  }
`;
