import styled from "styled-components";

export const Container = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 12.9rem;
  border: 0.1rem dashed ${({ theme }) => theme.prevColors.greyLight};
  border-radius: 0.3rem;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.prevColors.white};
  }

  svg {
    margin-bottom: 1.5rem;
    fill: ${({ theme }) => theme.prevColors.streamsBlue};
  }
`;

export const Text = styled.h2`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.2rem;
  margin: 0;
  color: ${({ theme }) => theme.prevColors.greyDark};
`;
