import styled, { css } from "styled-components";

export const Bar = styled.div`
  width: 0;
  height: 0.2rem;
  background-color: ${({ theme }) => theme.prevColors.greyLight};
  transition: width 0.2s;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const Input = styled.input`
  width: 20%;
  opacity: 0;
  transition: width 0.2s, opacity 0.2s;
  outline: none;

  &:focus {
    width: 100%;
    opacity: 1;

    + ${Bar} {
      width: 100%;
      background-color: ${({ theme }) => theme.prevColors.streamsBlue};
    }
  }
`;

const open = css`
  ${Input} {
    width: 100%;
    opacity: 1;
  }

  ${Bar} {
    width: 100%;
  }
`;

export const Container = styled.form`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0;

  ${({ hasValue }) => css`
    ${hasValue && open};

    &:hover {
      ${open};
    }
  `}

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    ${open}
  }

  svg {
    width: 2.4rem;
    height: 2.4rem;
    fill: ${({ theme }) => theme.prevColors.grey};

    @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
`;

export const Button = styled.button`
  flex-shrink: 0;
  margin-right: 1rem;
  cursor: pointer;
`;
