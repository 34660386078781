import styled, { css } from "styled-components";
import { default as BaseCheckbox } from "../../common/Checkbox";
import { default as BaseInput } from "../../common/Input";
import { default as BaseLabel } from "../../common/Label";

export const Container = styled.div`
  padding: 2.5rem 4rem 0;
  overflow: hidden;
  height: 100%;
  max-width: 512px;
`;

export const Step = styled.form`
  padding-bottom: ${({ modal }) => (modal ? "0" : "10rem")};
`;

export const Title = styled.h3`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 3rem;
  margin-bottom: 1.8rem;
  text-align: center;
  color: ${({ theme }) => theme.prevColors.greyDark};
`;

export const Text = styled.p`
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: ${({ theme }) => theme.prevColors.greyDark};
`;

export const Label = styled(BaseLabel)`
  margin: 1rem 0 0.5rem;
`;

export const Input = styled(BaseInput)`
  display: block;
  width: auto;
  min-width: 17.7rem;
  margin-bottom: 2rem;
`;

export const Checkbox = styled(BaseCheckbox)`
  margin: 1.5rem 0 2.8rem;

  span:first-of-type {
    width: 1.85rem;
    height: 1.85rem;
    margin-bottom: 7rem;
  }
`;

export const Link = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.prevColors.streamsBlue};
`;

const button = css`
  display: block;
  min-width: 17.7rem;
`;

export const Button = styled.button`
  ${button};
`;

export const Resend = styled.button`
  ${button};
  padding: 0;
`;

export const Errors = styled.div`
  margin-top: 2.4rem;
`;
