import styled from "styled-components";

export const Container = styled.button`
  display: flex;
  flex-shrink: 0;
  width: 4.1rem;
  padding: 0.2rem;
  cursor: pointer;
  border: 0;
  border-radius: 10rem;
  background-color: ${({ theme, isOn }) =>
    isOn ? theme.prevColors.streamsBlue : theme.prevColors.greyLight};
  transition: background-color 0.2s, border-color 0.2s;
`;

export const Pill = styled.span`
  display: block;
  width: 100%;
  position: relative;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 2rem;
    height: 2rem;
  }

  &:after {
    background-color: ${({ theme }) => theme.prevColors.white};
    border-radius: 50%;
    transform: translateX(${({ isOn }) => (isOn ? "-100%" : "0%")});
    transition: left 0.1s, transform 0.1s;
    position: absolute;
    left: ${({ isOn }) => (isOn ? "100%" : "0%")};
    top: 0;
  }
`;
