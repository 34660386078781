import { Link } from "@wrstudios/components";
import { navigateBack } from "@wrstudios/utils";
import PropTypes from "prop-types";
import React from "react";
import streamsAttractIntegrationImage from "../../../static/images/streams-attract-integration-graphic.png";
import {
  Box,
  Button,
  Container,
  Content,
  Header,
  Image,
  Logout,
  Text
} from "./styled/attract-connect-route";

function AttractConnectRoute({ attractConnectUrl, email, logout }) {
  return (
    <Container>
      <Content>
        <Header>Request For Permission</Header>
        <Box>
          <Image src={streamsAttractIntegrationImage} />
          <Text>
            <strong>Cloud Attract</strong> is requesting permission to create
            Cloud Streams
          </Text>
          <Text>
            Logged in as <strong>{email}</strong> (
            <Logout onClick={logout}>Not you?</Logout>)
          </Text>
          <Link href={attractConnectUrl} data-testid="cloud-attract-allow-link">
            <Button className="helix-btn helix-btn--primary">Allow</Button>
          </Link>
          <Button
            className="helix-btn helix-btn--secondary"
            onClick={navigateBack}>
            Cancel
          </Button>
        </Box>
      </Content>
    </Container>
  );
}

AttractConnectRoute.propTypes = {
  attractConnectUrl: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired
};

export default AttractConnectRoute;
