import styled from "styled-components";
import { default as BaseSelect } from "../../select/Select";
import { Input, InputContainer, Item, Menu } from "../../select/styled/select";

export const Container = styled.div`
  display: flex;
  position: relative;
`;

export const Select = styled(BaseSelect)`
  border: 0;
  position: relative;
  z-index: 10;

  &.is-open,
  &.is-focused {
    ${InputContainer} {
      border-color: ${({ theme }) => theme.prevColors.streamsBlue};
    }
  }

  ${InputContainer} {
    border: 0.2rem solid ${({ theme }) => theme.prevColors.greyLight};
    border-radius: 0.6rem;
  }

  ${Input} {
    font-size: 1.6rem;
    line-height: 2rem;
    padding: 1.4rem 2rem 1.4rem 4.4rem;
    color: ${({ theme }) => theme.prevColors.blackLight};
  }

  ${Menu} {
    margin-top: 0.2rem;
  }

  ${Item} {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.prevColors.greyDark};

    &.is-selected {
      background-color: ${({ theme }) => theme.prevColors.white};
    }

    &.is-selected.is-highlighted {
      background-color: ${({ theme }) => theme.prevColors.whiteDark};
    }
  }
`;

export const Search = styled.button`
  align-items: center;
  height: 100%;
  border: none;
  padding: 1.8rem;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  cursor: pointer;

  svg {
    width: 2.4rem;
    height: 2.4rem;
    fill: ${({ theme }) => theme.prevColors.blackLight};
  }
`;
