import styled from "styled-components";
import { default as BaseInput } from "../../../common/Input";
import { Container as StreamInviteClientContainer } from "./stream-invite-client";

export const Container = styled.div`
  -webkit-overflow-scrolling: auto;
  padding: 0 2.4rem;
  margin: 0 -2.4rem;
  overflow: hidden;

  ${StreamInviteClientContainer}:last-child {
    border-bottom: 0px;
    padding-bottom: 2rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    padding: 0;
    margin: 0;
  }
`;

export const CollapsedContainer = styled(Container)`
  margin: 0 -2.4rem;
  > div {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    &:first-of-type {
      padding-top: 0;
    }
    &:last-of-type {
      border-bottom: none;
    }
  }
`;

export const Search = styled.form`
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
  background-color: ${({ theme }) => theme.prevColors.whiteDark};
  border-radius: 0.4rem;
  position: relative;
`;

export const Input = styled(BaseInput)`
  padding: ${({ hasNewEmail }) =>
    hasNewEmail ? "0.8rem 11.5rem 0.8rem 0.8rem" : "0.8rem"};
  background-color: transparent;
  border-color: ${({ theme }) => theme.prevColors.whiteDark};

  &::placeholder {
    color: ${({ theme }) => theme.prevColors.blackLight};
  }
`;

export const Add = styled.button`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.9rem;
  color: ${({ theme }) => theme.prevColors.streamsBlue};
  cursor: pointer;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 1.2rem;
`;

export const Empty = styled.span`
  display: block;
  font-size: 1.4rem;
  font-style: italic;
  line-height: 2rem;
  margin: 1.6rem 0 1.6rem;
  color: ${({ theme }) => theme.prevColors.blackLight};
`;
