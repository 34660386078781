import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 4rem;
  background-color: ${({ theme }) => theme.prevColors.whiteDark}};
  border-radius: 0.3rem;
  outline: none;
  position: relative;

  ${({ isOverlay }) =>
    isOverlay &&
    css`
      box-shadow: ${({ theme }) => theme.shadows.overlay},
        0 0 0.1rem 0.1rem ${({ theme }) => theme.prevColors.streamsBlue};
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
    `}

  svg {
    width: 1.8rem;
    height: 1.8rem;
    margin-top: 0.3rem;
    fill: ${({ theme }) => theme.prevColors.blackLight};
  }

  // * Notes This is a hack to get around the static height from react virtualized.
  .ReactVirtualized__Grid.ReactVirtualized__List {
    width: 100% !important;
    height: auto !important;
    max-width: 100% !important;
    max-height: 30rem !important;
    outline: none !important;

    .ReactVirtualized__Grid__innerScrollContainer {
      width: 100% !important;
      max-width: 100% !important;
      outline: none !important;

      > div {
        outline: none !important;
      }
    }
  }
`;

export const InnerContainer = styled.div`
  min-height: 4rem;
  padding: 0.8rem 1.2rem;
  position: relative;
`;

export const Grid = styled.div`
  display: flex;
  margin: -0.6rem;
`;

export const Cell = styled.div`
  padding: 0.6rem;

  &:first-child {
    flex-shrink: 0;
  }

  &:last-child {
    flex-grow: 1;
  }
`;

export const List = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -0.2rem;
`;

export const ListItem = styled.div`
  padding: 0.2rem;
`;

export const Pill = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  line-height: 1.3rem;
  padding: 0.5rem 0.8rem 0.6rem;
  background-color: ${({ theme }) => theme.prevColors.streamsBlue};
  border-radius: 0.3rem;
  color: ${({ theme }) => theme.prevColors.white};
  white-space: nowrap;
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  height: 2rem;
  font-size: 1.6rem;
  line-height: 1.3rem;
  margin-top: 0.8rem;
  background-color: transparent;
  border: 0;
  appearance: none;
  outline: none;

  &::placeholder {
    line-height: normal;
    color: ${({ theme }) => theme.prevColors.greyLight};
  }

  ${({ hasItems }) =>
    !hasItems &&
    css`
      width: 100%;
      height: 100%;
      font-size: 1.6rem;
      line-height: 2rem;
      margin: 0;
      padding: 0 1.3rem 0 4.1rem;
      position: absolute;
      top: 0;
      left: 0;
    `}
`;

export const Remove = styled.button`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
  outline: none;
  cursor: pointer;

  svg {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    fill: ${({ theme }) => theme.prevColors.white};
  }
`;

export const AllItems = styled.div`
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Item = styled.div`
  width: 100%;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  padding: 0.8rem 0.8rem 0.8rem 4.1rem;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: ${({ theme }) => theme.prevColors.greyLighter};
  }

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      background-color: ${({ theme }) => theme.prevColors.greyLighter};
    `}
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  background-color: ${({ theme }) => theme.prevColors.white};
`;

export const Control = styled.button`
  font-size: 1.4rem;
  font-weight: 700;
  cursor: pointer;
  color: ${({ theme, isConfirm }) =>
    isConfirm ? theme.prevColors.streamsBlue : theme.prevColors.grey};
`;
