import styled from "styled-components";

export const Container = styled.button`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  margin: 0 1rem;
  cursor: pointer;
  color: ${({ theme, isActive }) =>
    isActive ? theme.prevColors.streamsBlue : theme.prevColors.grey};
  border-bottom: 0.2rem solid
    ${({ theme, isActive }) =>
      isActive ? theme.prevColors.streamsBlue : "transparent"};

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 340px) {
    margin: 0 0.8rem;
  }
`;
