import styled from "styled-components";

export const Container = styled.button`
  color: ${({ theme, active }) =>
    active ? theme.prevColors.streamsBlue : theme.prevColors.grey};
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.prevColors.streamsBlue};
  }
`;
