import { getProxiedImageUrl } from "@wrstudios/image-proxy";
import {
  currency,
  currencyShort,
  formatNumber,
  getDateLong,
  getDateShort,
  getDateTime,
  getInitials,
  getTimeAgo,
  pluralize
} from "@wrstudios/utils";
import { compact, parseInt, sortBy } from "lodash";
import titleize from "titleize";
import missingImageUrl from "../static/images/photo-not-available--small.png";

function getFeatures(listing) {
  let features = listing.features || (listing.data || {}).features || {};
  if (typeof features === "string") features = JSON.parse(features);
  return Object.entries(features).map(([field, value]) => ({
    field: titleize(field.replace(/([a-z0-9])([A-Z])/g, "$1 $2")),
    value
  }));
}

function getFeature(featureName, features) {
  const feature =
    features.find((feature) => feature.field === featureName) || {};
  return feature.value || "";
}

export function transformListings({ listings, mls }) {
  return listings.reduce((state, listing) => {
    const transformedListing = transformListing({ listing, mls });
    return { ...state, [transformedListing.id]: transformedListing };
  }, {});
}

export function transformListing({ listing, mls }) {
  const transformedPhotos = transformPhotos({
    photos: listing.photos || [],
    photoProxyStrategy: mls.photoProxyStrategy
  });

  return {
    id: listing.id || "",
    mlsnum: listing.mlsnum || "",
    latitude: listing.lat || "",
    longitude: listing.lon || "",
    zip: listing.zipcode || "",
    city: listing.city || "",
    state: listing.state || "",
    address: listing.address || "",
    addressFormatted: getAddressFormatted(listing),
    subAddress: getSubAddress(listing),
    price: `${listing.price || ""}`,
    priceFormatted: listing.price ? currency(listing.price) : "",
    priceFormattedShort: listing.price ? currencyShort(listing.price) : "",
    pricePerSqft:
      listing.price && listing.sqft
        ? `${currency(Math.round(listing.price / listing.sqft))}`
        : "",
    dom: listing.dom || "",
    domFormatted: listing.dom
      ? `${pluralize("days", parseInt(listing.dom), true)} on market`
      : "",
    beds: formatNumber(listing.beds, "0"),
    bedsFormatted: listing.beds ? pluralize("beds", listing.beds, true) : "",
    baths: listing.baths
      ? formatNumber(listing.baths, "0[.]0[0]")
      : getWeirdBathsValue(listing),
    bathsFormatted: listing.baths
      ? pluralize("baths", listing.baths, true)
      : getWeirdBathsValue(listing),
    garages: formatNumber(listing.garages, "0"),
    garagesFormatted: listing.garages
      ? pluralize("garage spaces", listing.garages, true)
      : "",
    built: formatNumber(listing.year_built, "0"),
    builtFormatted: listing.year_built ? `built in ${listing.year_built}` : "",
    sqft: formatNumber(listing.sqft, "0[.]0[0]"),
    sqftFormatted: listing.sqft ? `${listing.sqft} sqft` : "",
    acres: listing.acres
      ? `${listing.acres}`
      : listing.lotsize
      ? `${getAcresFromLotSize(listing.lotsize || "")}`
      : "",
    acresFormatted: listing.acres
      ? `${listing.acres} acre lot`
      : listing.lotsize
      ? `${getAcresFromLotSize(listing.lotsize || "")} acre lot`
      : "",
    lotSize: formatNumber(
      listing.lotsize
        ? listing.lotsize
        : getLotSizeFromAcres(listing.acres || ""),
      "0[.]0[0]"
    ),
    firstPhoto: transformedPhotos[0] || missingImageUrl,
    photos: transformedPhotos,
    propType: listing.prop_type || "",
    subType: listing.prop_sub_type || "",
    statusLabel: listing.status || "",
    statusValue: listing.mapped_status || "",
    description: listing.remarks || "",
    showings: listing.showing_inst || "",
    agentId: (listing.agent_list || {}).id || "",
    agentIdFromMls: (listing.agent_list || {}).agent_id || "",
    agentName: (listing.agent_list || {}).name || "",
    agentInitials: getInitials((listing.agent_list || {}).name),
    agentEmail: (listing.agent_list || {}).email || "",
    agentPhone: (listing.agent_list || {}).phone || "",
    officeId: (listing.office_list || {}).id || "",
    officeName: (listing.office_list || {}).name || "",
    details: transformDetails(listing),
    history: transformHistory(listing.changes),
    historyRaw: listing.changes,
    updatedAt: listing.updated_at || "",
    updatedAtAgo: getTimeAgo(listing.updated_at || ""),
    updatedAtDate: getDateLong(listing.updated_at || ""),
    updatedAtDateShort: getDateShort(listing.updated_at || ""),
    updatedAtTime: getDateTime(listing.updated_at || ""),
    virtualTour: getFeature("Virtual Tour", getFeatures(listing))
  };
}

export function transformPhotos({ photos, photoProxyStrategy }) {
  return photos.filter(Boolean).map(
    (photoUrl) =>
      getProxiedImageUrl({
        url: photoUrl.replace(/^https?:\/\/\s\/\//, "http://"),
        strategy: photoProxyStrategy
      }) || missingImageUrl
  );
}

export function transformSchools(schools) {
  return schools.map((school) => {
    return {
      title: school.name,
      grades: school.gradeRange,
      distance: `${school.distance} mi`
    };
  });
}

function transformHistory(changes) {
  if (!changes) {
    return [];
  }
  const changesObject = JSON.parse(changes);
  const dates = Object.keys(changesObject);

  return dates.reduce((state, date) => {
    const types = Object.keys(changesObject[date]);
    const initial = { date: getDateLong(date) };

    const updates = compact(
      types.map((type) => {
        switch (type) {
          case "price_list":
            if (!changesObject[date][type][1]) {
              return null;
            }
            return {
              ...initial,
              type: "price",
              description: getPriceUpdate(changesObject[date][type])
            };
          case "status":
            if (
              !changesObject[date][type][0] ||
              !changesObject[date][type][1]
            ) {
              return null;
            }
            return {
              ...initial,
              type: "status",
              description: getStatusUpdate(changesObject[date][type])
            };
          case "photos":
            if (
              !changesObject[date][type][0] ||
              !changesObject[date][type][1]
            ) {
              return null;
            }
            return {
              ...initial,
              type: "photos",
              description: getPhotosUpdate(changesObject[date][type])
            };
          default:
            return "";
        }
      })
    );

    return [...state, ...updates];
  }, []);
}

function transformDetails(listing) {
  const { features } = listing;

  if (!features) {
    return [];
  }

  const arbitraryFeaturesObject = JSON.parse(features);
  const standardFeatures = [
    { key: "assoc_fee", name: "Association Fee" },
    { key: "county", name: "County" },
    { key: "garages", name: "Garages" },
    { key: "lotdim", name: "Lot Dim" },
    { key: "mlsnum", name: "MLS #" },
    { key: "sale_rent", name: "Sale or Rent" },
    { key: "school_district", name: "School District" },
    { key: "school_elementary", name: "School Elementary" },
    { key: "school_high", name: "School High" },
    { key: "school_middle", name: "School Middle" },
    { key: "style", name: "Style" },
    { key: "subdivision", name: "Subdivision" },
    { key: "taxes", name: "Taxes" },
    { key: "year_built", name: "Year Built" }
  ];

  const featuresFromStandardFieldsObject = standardFeatures.reduce(
    (features, field) => {
      if (!listing[field.key]) {
        return features;
      }
      return {
        ...features,
        [field.name]: listing[field.key].toString()
      };
    },
    {}
  );

  const allFeaturesObject = {
    ...featuresFromStandardFieldsObject,
    ...arbitraryFeaturesObject
  };
  const details = Object.keys(allFeaturesObject).map((key) => {
    return {
      field: key,
      value: isFeatureInCurrency(key)
        ? currency(allFeaturesObject[key])
        : allFeaturesObject[key]
    };
  });

  return sortBy(details, "field");
}

function getWeirdBathsValue(listing) {
  const baths = [
    listing.baths_three_quarter,
    listing.baths_three_quarter,
    listing.baths_half,
    listing.baths_quarter
  ].filter(Boolean);

  if (!baths.length) return "";

  if (listing.baths_three_quarter > 0 || listing.baths_quarter > 0) {
    return `${listing.baths_full || 0}/${listing.baths_three_quarter || 0}/${
      listing.baths_half || 0
    }/${listing.baths_quarter || 0}`;
  } else {
    return `${listing.baths_full || 0}/${listing.baths_half || 0}`;
  }
}

function isFeatureInCurrency(field) {
  return [
    "Association Fee",
    "HOA Fee",
    "Assoc Dues 1",
    "Assoc Dues 2",
    "Land Lease Amount",
    "Land Lease Transfer Fee",
    "Taxes"
  ].includes(field);
}

function getPriceUpdate(update) {
  const firstPriceNumber = parseInt(update[0]);
  const secondPriceNumber = parseInt(update[1]);
  const firstPriceString = currency(firstPriceNumber);
  const secondPriceString = currency(secondPriceNumber);

  if (!update[0]) {
    return `Initial listing price set to ${secondPriceString}`;
  }
  if (firstPriceNumber < secondPriceNumber) {
    return `Price increased from ${firstPriceString} to ${secondPriceString}`;
  }
  return `Price decreased from ${firstPriceString} to ${secondPriceString}`;
}

function getStatusUpdate(update) {
  return `Status changed from ${update[0]} to ${update[1]}`;
}

function getPhotosUpdate(update) {
  if (update[0].length < update[1].length) {
    return "Photos were added";
  }
  return "Photos were removed";
}

function getAddressFormatted({ address, city, state, zip }) {
  return [address, city, [state, zip].filter(Boolean).join(" ")]
    .filter(Boolean)
    .join(", ");
}

function getSubAddress(listing) {
  return `${listing.city ? listing.city + "," : ""} ${listing.state || ""} ${
    listing.zipcode || ""
  }`.trim();
}

function getAcresFromLotSize(lotsize) {
  if (!lotsize) return lotsize;
  return (parseInt(lotsize) / 43560).toFixed(2);
}

function getLotSizeFromAcres(acres) {
  if (!acres) return acres;
  return (parseInt(acres) * 43560).toFixed(2);
}

// prettier-ignore
export const sizeOptions = [
  { label: "18 per page", value: 18 },
  { label: "36 per page", value: 36 },
  { label: "54 per page", value: 54 },
  { label: "72 per page", value: 72 },
  { label: "90 per page", value: 90 },
  { label: "108 per page", value: 108 }
];
