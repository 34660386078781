import styled from "styled-components";

export const Container = styled.div`
  color: ${({ theme }) => theme.prevColors.grey};
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.prevColors.streamsBlue};
  }
`;

export const CommentCount = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.2rem;
  color: ${({ theme }) => theme.prevColors.grey};
`;
