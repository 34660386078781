/**
 * Email Frequencies.
 */
export const emailFrequencies = {
  NEVER: "never",
  REAL_TIME: "realtime",
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
  RECOMMENDATIONS: "recommendations"
};

/**
 * SMS Frequencies.
 */
export const smsFrequencies = {
  NEVER: "none",
  REAL_TIME: "new_only"
};

/**
 * Alert Templates
 */
export const alertTemplates = {
  AUTOMATIC: "automatic",
  CURATED: "curated",
  SELF_SERVE: "self_serve"
};

/**
 * Grabs the frequency name from key.
 *
 * @param {String} frequency
 */
export function getFrequencyName(frequency) {
  switch (frequency) {
    case emailFrequencies.NEVER:
    case smsFrequencies.NEVER:
      return "Never";
    case emailFrequencies.REAL_TIME:
    case smsFrequencies.REAL_TIME:
      return "Real-time";
    case emailFrequencies.DAILY:
      return "Daily";
    case emailFrequencies.WEEKLY:
      return "Weekly";
    case emailFrequencies.MONTHLY:
      return "Monthly";
    case emailFrequencies.RECOMMENDATIONS:
      return "Recommendations";
    default:
      console.warn(`Unknown frequency name for frequency "${frequency}"`);
      return frequency;
  }
}

/**
 * Grabs the frequency label from key.
 *
 * @param {String} frequency
 */
export function getFrequencyLabel(frequency) {
  switch (frequency) {
    case emailFrequencies.NEVER:
    case emailFrequencies.REAL_TIME:
    case emailFrequencies.DAILY:
    case emailFrequencies.WEEKLY:
    case emailFrequencies.MONTHLY:
      return `${getFrequencyName(frequency)} Email`;
    case smsFrequencies.NEVER:
    case smsFrequencies.REAL_TIME:
      return `${getFrequencyName(frequency)} Text`;
    default:
      console.warn(`Unknown frequency label for frequency "${frequency}"`);
      return frequency;
  }
}

export function getDefaultFrequency(alertsTemplate) {
  switch (alertsTemplate) {
    case alertTemplates.AUTOMATIC:
      return emailFrequencies.REAL_TIME;
    case alertTemplates.CURATED:
      return emailFrequencies.RECOMMENDATIONS;
    default:
      console.warn(`Unknown alert template ${alertsTemplate}`);
      return alertsTemplate;
  }
}

export function getFontWeightByFrequency(frequency) {
  switch (frequency) {
    case emailFrequencies.NEVER:
    case smsFrequencies.NEVER:
      return 400;
    case emailFrequencies.REAL_TIME:
    case emailFrequencies.DAILY:
    case emailFrequencies.WEEKLY:
    case emailFrequencies.MONTHLY:
    case smsFrequencies.REAL_TIME:
    case emailFrequencies.RECOMMENDATIONS:
      return 600;
    default:
      console.warn(`Unknown frequency label for frequency "${frequency}"`);
      return 400;
  }
}

export function getFontColorByFrequency({ frequency, theme }) {
  switch (frequency) {
    case emailFrequencies.NEVER:
    case smsFrequencies.NEVER:
      return theme.prevColors.grey;
    case emailFrequencies.REAL_TIME:
    case emailFrequencies.DAILY:
    case emailFrequencies.WEEKLY:
    case emailFrequencies.MONTHLY:
    case smsFrequencies.REAL_TIME:
    case emailFrequencies.RECOMMENDATIONS:
      return theme.prevColors.streamsBlue;
    default:
      console.warn(`Unkown frequency label for frequency "${frequency}"`);
      return theme.prevColors.grey;
  }
}

/**
 * Filters down a list of clients by name using a search term
 *
 * @param {Array} clients
 * @param {String} term
 */
export function filterClients(clients, term) {
  if (!term) return clients;

  return clients.filter(({ name, email }) => {
    let found = name.toLowerCase().includes(term.toLowerCase());

    if (!found) {
      found = email.toLowerCase().includes(term.toLowerCase());
    }

    return found;
  });
}

/**
 * Map selected client ids to clients and filter out bad results.
 *
 * @param {Array} selectedClientIds The client ids that have been checked by the user.
 * @param {Array} clients All the clients.
 */
export function mapClientsToInvitees(selectedClientIds, clients) {
  const filteredClients = clients.filter(({ id }) =>
    selectedClientIds.includes(id)
  );

  return filteredClients.map(({ id, name, email }) => ({
    id,
    name,
    email,
    frequency: "realtime"
  }));
}

/**
 * Return whether a passed string is formatted as a valid email address
 *
 * @param {string} emailAddress
 */
export function isValidEmail(emailAddress) {
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  return pattern.test(emailAddress);
}
