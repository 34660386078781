import styled from "styled-components";

export const Container = styled.nav`
  display: flex;
  margin: 0 -0.9rem;
`;

export const LinkWrapper = styled.div`
  padding: 0 0.9rem;

  a {
    color: ${({ theme }) => theme.prevColors.greyMedium};
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 2rem;

    &.active {
      color: ${({ theme }) => theme.prevColors.streamsBlue};
      border-bottom: 0.2rem solid ${({ theme }) => theme.prevColors.streamsBlue};
    }
  }
`;
