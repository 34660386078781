import styled from "styled-components";

export const TextMessages = styled.div`
  margin: 1.5rem -5rem;
  padding: 2.4rem 5.5rem;
  border-top: 1px solid ${({ theme }) => theme.prevColors.greyLight};
  border-bottom: 1px solid ${({ theme }) => theme.prevColors.greyLight};
  background-color: ${({ theme }) => theme.prevColors.whiteDark};
`;

export const MessageBubble = styled.div`
  position: relative;
  width: 320px;
  padding: 1rem 1.5rem;
  line-height: 1.8rem;
  word-wrap: break-word;
  border-radius: 2rem;
  font-size: 1.3rem;
  background-color: ${({ theme }) => theme.prevColors.greyMedium};
  color: ${({ theme }) => theme.prevColors.white};

  &:before {
    left: -7px;
    width: 20px;
    border-bottom-right-radius: 15px;
    background-color: ${({ theme }) => theme.prevColors.greyMedium};
  }

  &:after {
    left: -26px;
    width: 26px;
    border-bottom-right-radius: 12px;
    background-color: ${({ theme }) => theme.prevColors.whiteDark};
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 25px;
  }
`;
