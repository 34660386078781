// There is a bug in styled components where @font-face will cause a rerender
// as the fonts will be force downloaded.
// This is a workaround.
// https://github.com/styled-components/styled-components/issues/1593
import "focus-visible";
import { createGlobalStyle } from "styled-components";
import "../../../static/fonts/fonts.css";

export const GlobalStyles = createGlobalStyle`
  *,
  *:after,
  *:before {
    box-sizing: inherit;
  }

  html {
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-weight: 400;
    font-stretch: normal;
    font-size: 62.5%;
    cursor: default;
  }

  body {
    background-color: ${({ theme }) => theme.prevColors.greyLightest};
  }

  html,
  body {
    height: 100%;
    overflow: hidden;
  }

  body,
  input,
  select,
  button,
  textarea  {
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1.6rem;
    color: ${({ theme }) => theme.prevColors.greyDark};
  }

  #root {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-overflow-scrolling: touch;
  }

  a {
    transition: color 0.1s;
    color: ${({ theme }) => theme.prevColors.streamsBlue};
    text-decoration: none;
    &:hover {
      text-decoration: none !important;
    }
  }

  input {
    background-color: transparent;
    -webkit-appearance: none;
    padding: 0;
    border: 0;
  }

  input,
  select,
  button {
    margin: 0;
    padding: 0;
    background-color: transparent;
    appearance: none;
    border: 0;
  }

  img,
  svg {
    vertical-align: middle;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }
`;
