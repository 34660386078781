import styled from "styled-components";

export const Container = styled.div`
  ${({ theme }) => theme.layers.overlay};
  min-width: 21.5rem;
  max-width: 41.5rem;
  margin-top: 1rem;
  padding: 2rem;
  background-color: ${({ theme }) => theme.prevColors.white};
  outline: none;
  position: fixed;
  z-index: 10;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
`;

export const Clear = styled.button`
  color: ${({ theme }) => theme.prevColors.grey};
`;

export const Apply = styled.button`
  margin-left: auto;
  color: ${({ theme }) => theme.prevColors.streamsBlue};
  box-shadow: none !important;
`;
