import styled from "styled-components";

export const Container = styled.div`
  ${({ theme }) => theme.text.small};
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 600;
  padding: 1.2rem 1.6rem;
  color: ${getStatusColor};
  background-color: ${getStatusBackgroundColor};
  border-radius: 0.4rem;

  svg {
    flex-shrink: 0;
    margin-right: 1.1rem;
    fill: currentColor;
  }
`;

function getStatusBackgroundColor({ theme, status }) {
  switch (status) {
    case "info":
      return theme.prevColors.pastelYellow;
    case "success":
      return theme.prevColors.cmaGreen;
    case "error":
      return theme.prevColors.attractRed;
    default:
      return theme.prevColors.greyLighter;
  }
}

function getStatusColor({ theme, status }) {
  switch (status) {
    case "success":
    case "error":
      return theme.prevColors.white;
    default:
      return theme.prevColors.greyMedium;
  }
}
