import { UserAvatar as BaseAvatar, Portal } from "@wrstudios/components";
import FocusTrap from "focus-trap-react";
import { AnimatePresence, motion } from "framer-motion";
import { difference, isEmpty, keys, omit, values } from "lodash";
import PropTypes from "prop-types";
import React, { useReducer } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";
import useDeepCompareEffect from "use-deep-compare-effect";
import { recommendStreamItem } from "../../actions/streamItem";
import Checkbox from "../common/Checkbox";
import IconClose from "../icons/IconClose";
import ListingMini from "../listing/ListingMini";
import { emailFrequencies, smsFrequencies } from "./invite/utils";

function reducer(state, { type, payload }) {
  switch (type) {
    case "SET_IS_SENDING":
      return { ...state, isSending: payload };
    case "SET_MESSAGE":
      return { ...state, message: payload };
    case "SET_EMAIL_NOTIFICATION":
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [payload.id]: {
            ...state.notifications[payload.id],
            email_recommendations: payload.notify
          }
        }
      };
    case "SET_SMS_NOTIFICATION":
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [payload.id]: {
            ...state.notifications[payload.id],
            sms_recommendations: payload.notify
          }
        }
      };
    case "SET_NOTIFICATIONS":
      return { ...state, notifications: payload };
    default:
      return state;
  }
}

function transformClientSubscriptions(clientSubscriptions) {
  return clientSubscriptions.reduce((byId, subscription) => {
    byId[subscription.id] = {
      stream_subscription_id: subscription.id,
      email_recommendations:
        subscription.emailFrequency === emailFrequencies.RECOMMENDATIONS,
      sms_recommendations: false
    };
    return byId;
  }, {});
}

export default function StreamItemRecommendModal({
  streamItemId,
  isOpen,
  onClose,
  onSent
}) {
  const reduxDispatch = useDispatch();
  const {
    isLoading,
    agentId,
    streamId,
    clientSubscriptions,
    listing,
    listingId,
    mls
  } = useSelector(({ user, network, streams, streamItems, listings }) => {
    const streamItem = streamItems.byId[streamItemId] || {};
    const stream = streams.byId[streamItem.streamId] || {};
    const clientSubscriptions = values(
      isEmpty(streamItem.subscriptions)
        ? stream.subscriptions
        : streamItem.subscriptions || {}
    ).filter((sub) => sub.userId !== user.id);
    const listing = listings.byId[streamItem.itemId] || {};

    return {
      isLoading: (network.CREATE_RECOMMENDATION_REQUEST || {}).isLoading,
      streamId: streamItem.streamId,
      agentId: user.id,
      mls: user.mlsCode,
      listingId: listing.id,
      clientSubscriptions,
      listing
    };
  }, shallowEqual);

  const [{ message, notifications }, dispatch] = useReducer(reducer, {
    message: "",
    notifications: transformClientSubscriptions(clientSubscriptions)
  });

  useDeepCompareEffect(() => {
    // if subscribers to this stream have been changed, we need to re-derive the notification preferences
    if (notifications.length !== clientSubscriptions.length) {
      const transformedClientSubscriptions =
        transformClientSubscriptions(clientSubscriptions);
      const currentSubscriptionIds = keys(notifications);
      const newSubscriptionIds = keys(transformedClientSubscriptions);
      // keep the existing notification preferences in case the user has changed them from the default derived values, then remove stale clients that are no longer subscribed to this stream
      const removedClientIds = difference(
        currentSubscriptionIds,
        newSubscriptionIds
      );
      const newClientSubscriptions = omit(
        { ...transformedClientSubscriptions, ...notifications },
        removedClientIds
      );
      dispatch({ type: "SET_NOTIFICATIONS", payload: newClientSubscriptions });
    }
  }, [clientSubscriptions, notifications]);

  return (
    <AnimatePresence>
      {isOpen && (
        <Portal>
          <FocusTrap
            focusTrapOptions={{
              initialFocus: "#recommendation-message"
            }}>
            <Container onKeyDown={(e) => e.key === "Escape" && onClose()}>
              <Backdrop
                key="backdrop"
                onClick={onClose}
                {...backdropAnimationConfig}
              />
              <Modal key="modal" {...modalAnimationConfig}>
                <ModalSection>
                  <CloseButton onClick={onClose}>
                    <IconClose />
                  </CloseButton>
                  <ModalTitle>Recommend</ModalTitle>
                  {clientSubscriptions.map(
                    ({ id, smsFrequency, subscriber }) =>
                      subscriber && (
                        <Subscription key={id}>
                          <SubscriberAvatar
                            size="xs"
                            avatarUrl={subscriber.avatarUrl}
                            initials={subscriber.initials}
                          />
                          <SubscriberName>{subscriber.name}</SubscriberName>
                          <NotificationOptions>
                            <Checkbox
                              size="small"
                              checked={notifications[id].email_recommendations}
                              onChange={(e) =>
                                dispatch({
                                  type: "SET_EMAIL_NOTIFICATION",
                                  payload: { notify: e.target.checked, id }
                                })
                              }>
                              Email
                            </Checkbox>
                            <Checkbox
                              size="small"
                              disabled={smsFrequency === smsFrequencies.NEVER}
                              checked={notifications[id].sms_recommendations}
                              onChange={(e) =>
                                dispatch({
                                  type: "SET_SMS_NOTIFICATION",
                                  payload: { notify: e.target.checked, id }
                                })
                              }>
                              Text
                            </Checkbox>
                          </NotificationOptions>
                        </Subscription>
                      )
                  )}
                </ModalSection>
                <ModalSection>
                  <ListingMiniCardLayout>
                    <ListingMini listing={listing} />
                  </ListingMiniCardLayout>
                </ModalSection>
                <ModalSection color="greyLightest">
                  <MessageLayout>
                    <MessageInput
                      value={message}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_MESSAGE",
                          payload: e.target.value
                        })
                      }
                      placeholder="Add message (optional)"
                      id="recommendation-message"
                    />
                    <SendButton
                      onClick={async () => {
                        await reduxDispatch(
                          recommendStreamItem({
                            notifications: values(notifications),
                            streamItemId,
                            listingId,
                            streamId,
                            agentId,
                            message,
                            mls
                          })
                        );
                        onSent();
                        // streamRouteDispatch({ type: "RECOMMENDATION_SENT" });
                      }}
                      disabled={isLoading}
                      className="helix-btn helix-btn--primary">
                      {isLoading
                        ? "Sending Recommendation..."
                        : "Send Recommendation"}
                    </SendButton>
                  </MessageLayout>
                </ModalSection>
              </Modal>
            </Container>
          </FocusTrap>
        </Portal>
      )}
    </AnimatePresence>
  );
}

StreamItemRecommendModal.defaultProps = {
  onClose: () => {},
  onSent: () => {}
};

StreamItemRecommendModal.propTypes = {
  streamItemId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSent: PropTypes.func
};

// Styled Components
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    align-items: flex-start;
    justify-content: flex-start;
    background-color: white;
  }
`;

const Backdrop = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    display: none;
  }
`;

const Modal = styled(motion.div)`
  position: relative;
  width: 37.6rem;
  max-width: 100%;
  border-radius: 0.6rem;
  ${({ theme }) => theme.layers.modal}
  border: 0.1rem solid ${({ theme }) => theme.prevColors.greyLighter};
  z-index: 2;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    width: 100%;
  }
`;

const ModalSection = styled.div`
  padding: 0 2.4rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.prevColors.greyLighter};
  background-color: ${({ theme, color }) => theme.colors[color || "white"]};

  &:first-of-type {
    border-radius: 0.6rem 0.6rem 0 0;
  }

  &:last-of-type {
    border-bottom: none;
    border-radius: 0 0 0.6rem 0.6rem;
    position: sticky;
    bottom: 0;
    z-index: 100;
  }
`;

const ModalTitle = styled.h3`
  ${({ theme }) => theme.text.h3}
  margin: 1.9rem 0 1.6rem;
`;

const Subscription = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid ${({ theme }) => theme.prevColors.greyLighter};
  padding: 1.2rem 0;

  &:last-of-type {
    border-bottom: none;
  }
`;

const SubscriberAvatar = styled(BaseAvatar)`
  margin-right: 1.2rem;
`;

const SubscriberName = styled.span`
  ${({ theme }) => theme.text.small};
  font-weight: 600;
`;

const NotificationOptions = styled.div`
  margin-left: auto;

  > * + * {
    margin-left: 2.4rem;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1.4rem;
  right: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  border: none;
  padding: 0;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.prevColors.blackLight};
  }
`;

const MessageLayout = styled.div`
  padding: 1.4rem 0 1.6rem;
`;

const ListingMiniCardLayout = styled.div`
  padding: 1.2rem 0 2.4rem;
`;

const MessageInput = styled(TextareaAutosize)`
  ${({ theme }) => theme.inputs.base}
  min-height: 4rem;
  resize: none;
  margin-bottom: 1rem;
`;

const SendButton = styled.button`
  width: 100%;
  text-align: center;
  justify-content: center;
`;

// Motion Configs
const backdropAnimationConfig = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
};

const modalAnimationConfig = {
  initial: {
    zIndex: 100,
    opacity: 0,
    scale: 0.9
  },
  animate: {
    opacity: 1,
    scale: 1
  },
  exit: {
    opacity: 0,
    scale: 0.9
  }
};
