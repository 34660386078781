import styled from "styled-components";
import { Action as BaseAction } from "./listing-controls";

export const Container = styled.div`
  position: relative;
`;

export const Action = styled(BaseAction)`
  position: absolute;
  top: -0.4rem;
  right: 0;
`;

export const List = styled.ul`
  font-size: 1.4rem;
  line-height: 2.9rem;
  margin: 0 0 1.2rem;
  padding: 0;
  list-style: none;
`;

export const Group = styled.div`
  display: flex;
  margin: 0 -0.4rem;

  &:last-child {
    ${List} {
      margin-bottom: 0;
    }
  }
`;

export const Icon = styled.div`
  padding: 0 0.4rem;
  transform: translateY(-0.1rem);

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const Content = styled.div`
  padding: 0 0.4rem;
`;

export const Header = styled.strong`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2rem;
  margin-bottom: 0.6rem;
  color: ${({ theme }) => theme.prevColors.grey};
`;

export const Item = styled.li``;

export const Link = styled.a`
  color: ${({ theme }) => theme.prevColors.grey};
`;
