import { Modal } from "@wrstudios/components";
import styled from "styled-components";

export const ModalContent = styled(Modal.Content)`
  padding: 0;
`;

export const ModalBody = styled(Modal.Body)`
  margin: 0;
  padding: 0 0 3rem;
  position: relative;
`;

export const Close = styled.button`
  cursor: pointer;
  position: absolute;
  top: 1.3rem;
  right: 1.3rem;

  svg {
    fill: ${({ theme }) => theme.prevColors.greyMedium};
  }
`;
