import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import IconSaveStream from "../../icons/IconSaveStream";
import { Container, PokerChips, ShrinkWrapper } from "./styled/stream-invite";

function StreamInvite({
  hasLoadedStream,
  clients,
  isAgent,
  page,
  limit,
  getClients
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    getClients({ page, limit });
  }, [getClients, limit, page]);

  function handlePokerChipClick() {
    dispatch({ type: "OPEN_STREAM_MODAL_VIA_ALERTS" });
  }

  return (
    hasLoadedStream && (
      <ShrinkWrapper>
        <PokerChips
          clients={clients}
          size="xs"
          limit={2}
          onClick={handlePokerChipClick}
          onMoreClick={handlePokerChipClick}
        />
        <Container
          className="helix-btn helix-btn--secondary"
          onClick={() => dispatch({ type: "OPEN_STREAM_MODAL_VIA_ALERTS" })}>
          <IconSaveStream />
          Alerts
        </Container>
      </ShrinkWrapper>
    )
  );
}

StreamInvite.propTypes = {
  clients: PropTypes.array.isRequired,
  isAgent: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  getClients: PropTypes.func.isRequired
};

export default StreamInvite;
