import styled from "styled-components";
import {
  Input,
  Item,
  ContainerInner as Select,
  Selected,
  SelectedItem,
  SelectedItemInput
} from "../../select/styled/select";

export const Container = styled.div`
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: ${({ theme }) => theme.prevColors.black};

  ${Select} {
    border: 0;
    background-color: transparent;
    color: ${({ theme }) => theme.prevColors.black};

    &.is-focused {
      outline: auto 0.5rem -webkit-focus-ring-color;
    }

    svg {
      width: 1.8rem;
      height: 1.8rem;
      fill: ${({ theme }) => theme.prevColors.black};
    }

    ${Input} {
      padding-right: 3rem;
      text-align: right;
      color: ${({ theme }) => theme.prevColors.black};

      @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
        text-align: left;
      }
    }

    ${Item} {
      text-align: right;

      @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
        text-align: left;
      }
    }

    ${Selected} {
      padding: 0;
    }

    ${SelectedItem},
    ${SelectedItemInput} {
      margin: 0;
    }
  }
`;
