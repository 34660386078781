import styled from "styled-components";

export const Container = styled.div`
  height: calc(100vh - 6rem);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

export const Content = styled.div`
  max-width: 55.2rem;
  text-align: center;
`;

export const Box = styled.div`
  ${({ theme }) => theme.layers.raised};
  padding: 3rem;
`;

export const Header = styled.h2`
  ${({ theme }) => theme.text.h2};
  margin-bottom: 5rem;
`;

export const Text = styled.p`
  ${({ theme }) => theme.text.medium};
  margin: 0 0 2rem;
`;

export const Logout = styled.button`
  ${({ theme }) => theme.text.medium};
  color: ${({ theme }) => theme.prevColors.streamsBlue};
  cursor: pointer;
`;

export const Errors = styled.div`
  margin-top: 2rem;
`;

export const Button = styled.button`
  margin: 0 0.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
    display: block;
    width: 100%;
    margin: 0;
  }

  &:first-child {
    @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
      margin-bottom: 1rem;
    }
  }

  &:last-child {
    @media (max-width: ${({ theme }) => theme.breakpoints.page.oneColumn}px) {
      margin-top: 1rem;
    }
  }
`;
