import { Link } from "@wrstudios/components";
import PropTypes from "prop-types";
import React from "react";
import Activities from "../../activity/connected/Activities";
import ActivityStats from "../../activity/connected/ActivityStats";
import IconAddCircle from "../../icons/IconAddCircle";
import {
  Body,
  Container,
  Header,
  NewCircle,
  Title,
  TitleTip
} from "./styled/activity-route";

function ActivityRoute({ isAgent }) {
  return (
    <Container>
      <Header>
        <Title>
          Activity <TitleTip>Past 90 Days</TitleTip>
        </Title>
        {isAgent && (
          <Link to="/streams/new">
            <button className="helix-btn helix-btn--primary">
              Create Stream
            </button>
          </Link>
        )}
      </Header>
      <Body>
        <ActivityStats />
        <Activities />
      </Body>
      {isAgent && (
        <Link to="/streams/new">
          <NewCircle>
            <IconAddCircle />
          </NewCircle>
        </Link>
      )}
    </Container>
  );
}

ActivityRoute.propTypes = {
  isAgent: PropTypes.bool.isRequired
};

export default ActivityRoute;
