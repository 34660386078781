import styled from "styled-components";

export const Container = styled.div`
  display: flex;

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    max-height: unset;
    height: inherit;
  }
`;
