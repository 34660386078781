import { resetScrollPosition } from "@wrstudios/utils";
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { getClients } from "../../modules/stream";
import FadeTransition from "../common/FadeTransition";
import Pagination from "../common/Pagination";
import ThreeColumnLayout from "../common/ThreeColumnLayout";
import StreamCard from "./connected/StreamCard";
import StreamCardCreate from "./StreamCardCreate";
import StreamCardPokerChips from "./StreamCardPokerChips";
import StreamCardsLoading from "./StreamCardsLoading";
import StreamCardSocial from "./StreamCardSocial";

class StreamCards extends Component {
  render() {
    const {
      user,
      streams,
      streamIds,
      hasLoaded,
      page,
      limit,
      totalCount,
      deleteStream
    } = this.props;

    return (
      <>
        <ThreeColumnLayout>
          <StreamCardCreate />
          {streamIds.map((streamId) => {
            const stream = streams.byId[streamId] || {};
            const { likesCount, commentsCount } = stream;

            return (
              <StreamCard
                key={streamId}
                streamId={streamId}
                deleteStream={() => deleteStream({ streamId })}
                footer={
                  <>
                    <StreamCardPokerChips
                      clients={getClients({ stream, user })}
                    />
                    <StreamCardSocial
                      likes={likesCount}
                      comments={commentsCount}
                    />
                  </>
                }
              />
            );
          })}
        </ThreeColumnLayout>
        <FadeTransition mountsOn={!hasLoaded}>
          <StreamCardsLoading limit={limit} />
        </FadeTransition>
        {totalCount > limit && (
          <Pagination
            pageSize={limit}
            totalCount={totalCount}
            currentPage={page}
            showInfo={false}
            showLimitSelection={false}
            handleClickPage={this.handleClickPage}
          />
        )}
      </>
    );
  }

  componentDidMount() {
    if (this.props.hasLoaded) return;

    this.props.initStreamsIndex();
    this.props.getStreams({ page: this.props.page, limit: this.props.limit });
  }

  componentDidUpdate(prevProps) {
    const listingIdsByMlsHaveChange = !isEqual(
      prevProps.listingIdsByMls,
      this.props.listingIdsByMls
    );

    if (listingIdsByMlsHaveChange) {
      this.props.listingIdsByMls.forEach(([mlsCode, listingIds]) => {
        this.props.getListings({ mlsCode, ids: listingIds });
      });
    }
  }

  componentWillUnmount() {
    if (this.props.page !== 1) {
      this.props.resetStreams();
    }
  }

  handleClickPage = (page) => {
    this.props.getStreams({ page, limit: this.props.limit });
    resetScrollPosition();
  };
}

StreamCards.propTypes = {
  user: PropTypes.object.isRequired,
  streams: PropTypes.object.isRequired,
  streamIds: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  initStreamsIndex: PropTypes.func.isRequired,
  listingIdsByMls: PropTypes.array.isRequired,
  getStreams: PropTypes.func.isRequired,
  deleteStream: PropTypes.func.isRequired,
  resetStreams: PropTypes.func.isRequired
};

export default StreamCards;
