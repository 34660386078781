import styled from "styled-components";

export const Container = styled.a`
  display: flex;
  align-items: stretch;
  border: 0.1rem solid #2939a3;
  border-radius: 0.4rem;
  cursor: pointer;
`;

export const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 3.6rem;
  background-color: #2939a3;

  svg {
    width: 1.8rem;
    height: 1.4rem;
    fill: ${({ theme }) => theme.prevColors.white};
  }
`;

export const Text = styled.span`
  display: block;
  flex-grow: 1;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  padding: 0.8rem 1rem;
  color: #2939a3;
  text-align: center;
`;
